import i18n from "i18next"
import { reactI18nextModule } from "react-i18next"

import Backend from "i18next-chained-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"
import LocalStorageBackend from "i18next-localstorage-backend" // primary use cache

import en from "./locales/en/translation.json"
import de from "./locales/de/translation.json"
import dt from "./locales/dt/translation.json"
import fn from "./locales/fn/translation.json"
import fr from "./locales/fr/translation.json"
import it from "./locales/it/translation.json"
import ko from "./locales/ko/translation.json"
import pt from "./locales/pt/translation.json"
import rs from "./locales/rs/translation.json"
import sp from "./locales/sp/translation.json"
import vi from "./locales/vi/translation.json"
import ch from "./locales/ch/translation.json"
import po from "./locales/po/translation.json"
import jp from "./locales/jp/translation.json"
import tc from "./locales/tc/translation.json"
import sw from "./locales/sw/translation.json"

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  dt: {
    translation: dt,
  },
  fn: {
    translation: fn,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  ko: {
    translation: ko,
  },
  pt: {
    translation: pt,
  },
  rs: {
    translation: rs,
  },
  sp: {
    translation: sp,
  },
  vi: {
    translation: vi,
  },
  ch: {
    translation: ch,
  },
  po: {
    translation: po,
  },
  jp: {
    translation: jp,
  },
  tc: {
    translation: tc,
  },
  sw: {
    translation: sw,
  },
}

// import XHR from 'i18next-xhr-backend'; // have a own xhr fallback
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const backendOptions = {
  loadPath: "/locales/{{lng}}/{{ns}}.json",
}
const cacheOptions = {
  // prefix for stored languages
  prefix: "i18next_res_",

  // expiration
  expirationTime: 7 * 24 * 60 * 60 * 1000,

  // Version applied to all languages, can be overriden using the option `versions`
  // defaultVersion: '',

  // language versions
  // versions: {},

  // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
  store: window.localStorage,
}

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(reactI18nextModule)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    resources,
    // debug: true,
    // backend: {
    //   backends: [
    //     LocalStorageBackend,
    //     HttpApi, // fallback
    //   ],
    //   backendOptions: [cacheOptions, backendOptions],
    // },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
