import React, { useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { createClient } from "@supabase/supabase-js"

import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { login, syncData } from "../redux/states/app"
import Alert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import { getUniqueId, setUniqueId } from "../services/firebase"
import { Text, TouchableOpacity } from "react-native-web"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

function SignIn({ dataSync, signIn, app, user }) {
  const classes = useStyles()
  let history = useHistory()
  const [login, setLogin] = React.useState(true)
  const [email, setEmail] = React.useState(null)
  const [password, setPassword] = React.useState(null)
  const [confirmPassword, setConfirmPassword] = React.useState(null)
  const [name, setName] = React.useState(null)
  const [error, setError] = useState(null)

  const supabase = createClient(
    "https://wemetfkyevvxlvvxvstm.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzNzY4NzY5MywiZXhwIjoxOTUzMjYzNjkzfQ.W5Mel9QbXffCTJmPs6vzr0hmB977SAHvdsaX9_hfGZw",
  )

  function guidGenerator() {
    var S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          {!login && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="Name"
              label="Name"
              id="name"
              onChange={e => {
                setName(e.target.value)
              }}
              autoComplete="name"
              // style={{ marginBottom: 16 }}
            />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            // style={{ marginBottom: 16 }}
            onChange={e => {
              setPassword(e.target.value)
            }}
          />
          {!login && (
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="Confirm password"
              label="Confirm Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => {
                setConfirmPassword(e.target.value)
              }}
              // style={{ marginBottom: 16 }}
            />
          )}

          <TouchableOpacity
            // disabled={value.length != 1}
            style={{
              flex: 1,
              justifyContent: "center",
              padding: 4,
            }}
            onPress={() => {
              window.location.href = "https://apps.murphysmagic.com/forgot"
            }}
          >
            <Text style={{ color: "white", fontSize: 16 }}>
              Forget Password?
            </Text>
          </TouchableOpacity>

          <Button
            fullWidth
            variant="outlined"
            color="primary"
            style={{ padding: 16, marginTop: 16 }}
            onClick={() => {
              if (email && password) {
                supabase.auth
                  .signIn({
                    email: email,
                    password: password,
                  })
                  .then(async ({ user, session, error }) => {
                    if (error) {
                      let errorMessage = error.message
                      setError(errorMessage)
                    } else {
                      const res = await getUniqueId(email)
                      const data = res.data()

                      console.log("Data : ", data)
                      if (data)
                        localStorage.setItem(
                          "user",
                          JSON.stringify({ ...user, uniqueId: data.userId }),
                        )
                      else {
                        const time = Date.now()
                        await setUniqueId(email, { userId: time })
                        localStorage.setItem(
                          "user",
                          JSON.stringify({ ...user, uniqueId: time }),
                        )
                      }

                      localStorage.setItem(
                        "randomId",
                        JSON.stringify(guidGenerator()),
                      )
                      history.replace("/")
                    }
                  })
              }
            }}
          >
            {login ? "Login" : "Sign In"}
          </Button>
          <Grid container>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 15,
              }}
            ></div>
          </Grid>
        </form>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!!error}
          autoHideDuration={3000}
          onClose={() => setError(null)}
        >
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  )
}

export default connect(
  state => ({
    app: state.app,
    user: state.auth.user,
  }),
  dispatch => ({
    dataSync: payload => dispatch(syncData(payload)),
    signIn: () => dispatch(login()),
  }),
)(SignIn)
