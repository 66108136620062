import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { View } from "react-native-web"
import { validateLicense } from "../helpers/validation"
import Routes from "../routers/Routes"
import { connect } from "react-redux"
import { login } from "../redux/states/auth"
import { DefaultThemeProvider, GlobalThemes } from "../style/themes"
import AlertDialogSlide from "../component/Alert"

const AuthLoading = ({ user, app, theme }) => {
  const saved = localStorage.getItem("user")
  const me = saved ? JSON.parse(saved) : null
  const savedid = localStorage.getItem("randomId")
  const [isLoading, setIsLoading] = React.useState(true)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOnline, setIsOnline] = React.useState(navigator.onLine)

  React.useEffect(() => {
    console.log("-----------------------------------")
    console.log(isOnline)
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine)
    }

    // Listen to the online status
    window.addEventListener("online", handleStatusChange)

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange)

    if (me) {
      if (isOnline) {
        const { id } = me
        const appId = "106fc7dc-0b73-4583-b7d3-eb401bc609f5"
        const phone = navigator.userAgent
        const randomId = JSON.parse(savedid)
        ;(async () => {
          const rawResponse = await fetch(
            "https://apps.murphysmagic.com/api/verify",
            {
              method: "POST",
              headers: {
                "access-control-allow-origin": "*",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                user: id,
                appID: appId,
                deviceName: phone,
                deviceID: randomId,
              }),
            },
          )
          const content = await rawResponse.json()
          const { type } = content
          // console.log("-=---------------------------------------------", type);
          if (type != "success") {
            setIsOpen(true)
            setIsLoading(true)
          } else {
            setIsLoading(false)
          }
        })()
      } else {
        setIsLoading(false)
      }
    } else {
      setIsLoading(false)
    }
    return () => {
      window.removeEventListener("online", handleStatusChange)
      window.removeEventListener("offline", handleStatusChange)
    }
  })
  return (
    <DefaultThemeProvider theme={GlobalThemes[theme]}>
      {isLoading ? (
        <>
          <AlertDialogSlide
            open={isOpen}
            confirm={confirm => {
              if (confirm) {
                window.open("https://apps.murphysmagic.com", "_blank")
              }
              setIsOpen(false)
            }}
            title={"All your licenses for this app have been used up!"}
            message={
              "Please go to (https://apps.murphysmagic.com) to reset your license."
            }
          />
          ;
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </View>
        </>
      ) : (
        <>
          <Routes
            user={user}
            valid={validateLicense(
              {
                dateLogin: app.dateLogin,
                token: app.token,
                activationKey: app.activationKey,
              },
              user,
            )}
          />
        </>
      )}
    </DefaultThemeProvider>
  )
}

export default connect(
  state => ({
    app: state.app,
    user: state.auth.user,
    theme: state.app.theme,
  }),
  dispatch => ({
    loginUser: payload => dispatch(login(payload)),
  }),
)(AuthLoading)
