import React, { useEffect, useState } from 'react';
import { Image, Dimensions } from 'react-native-web';

const { width } = Dimensions.get('window');
const ScaledImage = ({ uri }) => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    Image.getSize(uri, (_width, _height) => {
      setHeight(_height * (width / _width));
    });
  }, [uri]);
  return (
    <Image
      ResizeMode={'Stretch'}
      source={{ uri }}
      style={{ width, height, resizeMethod: 'Stretch' }}
    />
  );
};

export default ScaledImage;
