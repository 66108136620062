import { createTheming } from "@callstack/react-theme-provider"
import iOS13 from "./iOS13Styles"
import androidSamsung from "./AndroidSumsungStyles"
import androidSamsungDark from "./AndroidSumsungDarkStyles"

export const {
  ThemeProvider: DefaultThemeProvider,
  useTheme,
  withTheme,
} = createTheming()

export const GlobalThemes = {
  iOS13,
  androidSamsung,
  androidSamsungDark,
}
