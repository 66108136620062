import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { resetPassword } from '../services/firebase';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const ForgetPassword = () => {
  const [key, setKey] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const history = useHistory();
  const sendResetPassword = () => {
    resetPassword(key)
      .then(r => {
        console.log('resetPassword', r);
        history.push('/login');
      })
      .catch(e => {
        console.log(e);
        setErrorMessage(e.message);
        setOpen(true);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <form style={{ width: '80%' }} noValidate autoComplete="off">
        <TextField
          style={{ marginBottom: 16 }}
          fullWidth
          label="Email"
          variant="outlined"
          placeholder="Email"
          onChange={e => {
            setKey(e.target.value);
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={sendResetPassword}
        >
          <h5>Reset Password</h5>
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ForgetPassword;
