import React, { useEffect, useRef, useState } from "react"
import { ScrollView, Text, View } from "react-native-web"
import { useHistory } from "react-router-dom"
import DoubleTapLink from "./DoubleTapLink"
import { connect } from "react-redux"
import { selectPreset } from "../redux/states/app"
import { useTheme } from "../style/themes"

const Tabs = ({ children, select, themeKey }) => {
  let history = useHistory()
  const ref = useRef(null)
  const [selectedItem, setSelectedItem] = useState(2)
  const [width, setWidth] = useState(getWindowSize().innerWidth)
  const [height, setHeight] = useState(getWindowSize().innerHeight)
  const theme = useTheme()

  useEffect(() => {
    ref.current.scrollTo({
      x: width * (themeKey == "iOS13" ? 3 : 2),
      y: 0,
      animated: false,
    })
  }, [themeKey])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  useEffect(() => {
    function handleWindowResize() {
      const size = getWindowSize()
      setWidth(size.innerWidth)
      setHeight(size.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  return (
    <View style={{ flex: 1, width, height }}>
      <ScrollView
        ref={ref}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        scrollEnabled={false}
        onScroll={value => {
          console.log(value)
        }}
        contentContainerStyle={{ width }}
        style={{ flex: 1 }}
      >
        {children[2]}
        {/*{children.map((child, index) =>*/}
        {/*  child ? (*/}
        {/*    <View key={`tabChild${index}`} style={{ width, height: '100%' }}>*/}
        {/*      {child}*/}
        {/*    </View>*/}
        {/*  ) : null,*/}
        {/*)}*/}
      </ScrollView>
      <View style={theme?.tabs?.container}>
        {children.map((child, index) =>
          child ? (
            <DoubleTapLink
              key={`tab${index}`}
              style={theme?.tabs?.doubleClick}
              onClick={() => {
                // if (index == 3) {
                //   ref.current.scrollTo({
                //     x: width * index,
                //     y: 0,
                //     animated: false,
                //   });
                //   setSelectedItem(index);
                // }
              }}
              onDoubleClick={() => {
                switch (index) {
                  case 0:
                    history.push(themeKey == "iOS13" ? "/settings" : "/input")
                    break
                  case 1:
                    history.push(themeKey == "iOS13" ? "/input" : "/presets")
                    break
                  case 2:
                    history.push("/presets")
                    break
                  case 3:
                    history.push("/second-input")
                    break
                }
              }}
            >
              {index === selectedItem
                ? child.props.activeIcon
                : child.props.icon}
              <Text
                style={
                  index === selectedItem
                    ? theme?.tabs.selectedTabTitleText
                    : theme?.tabs.tabTitleText
                }
                numberOfLines={1}
              >
                {child.props.title}
              </Text>
            </DoubleTapLink>
          ) : null,
        )}
      </View>
    </View>
  )
}

export default connect(
  state => ({
    themeKey: state.app.theme,
  }),
  dispatch => ({
    select: payload => dispatch(selectPreset(payload)),
  }),
)(Tabs)
