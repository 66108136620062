import React from "react"
import { View } from "react-native-web"
import Text from "react-native-web/dist/exports/Text"
import { useTheme } from "../style/themes"
import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"

function LapTile({
  item,
  index,
  length,
  select,
  themeKey,
  onTime,
  isMax,
  isMin,
  lapsLength,
  t,
}) {
  const theme = useTheme()

  let text = onTime
  // let text = length - 1 === index ? item : onTime
  // if (length - 2 === index)
  //   text = onTime.replace(/.{0,2}$/, "") + item.slice(-2)
  // if (length - 3 === index)
  //   text = onTime.replace(/.{0,2}$/, "") + item.slice(-2)

  for (let i = 0; i < lapsLength; i++) {
    text = onTime.replace(/.{0,2}$/, "") + item.slice(-2)
  }

  return (
    <div onClick={select}>
      <View style={theme?.lapTile.container}>
        <Text
          style={[
            theme?.lapTile.textLap,
            isMax && themeKey === "iOS13"
              ? { color: theme?.colors.stopColor }
              : {},
            isMin && themeKey === "iOS13"
              ? { color: theme?.colors.startColor }
              : {},
          ]}
        >
          {item != ""
            ? `${
                themeKey == "iOS13"
                  ? t("Lap") + " "
                  : length - index < 10
                  ? "0"
                  : ""
              }${length - index}`
            : " "}
        </Text>
        {themeKey == "iOS13" ? null : (
          <Text style={theme?.lapTile.text}>{text}</Text>
        )}
        {item != "" && (
          <Text
            style={[
              theme?.lapTile.textLap,
              isMax && themeKey == "iOS13"
                ? { color: theme?.colors.stopColor }
                : {},
              isMin && themeKey == "iOS13"
                ? { color: theme?.colors.startColor }
                : {},
            ]}
          >
            {item}
          </Text>
        )}
      </View>
    </div>
  )
}

export default connect(
  state => {
    return {
      themeKey: state.app.theme,
    }
  },
  dispatch => ({}),
)(withNamespaces()(LapTile))
