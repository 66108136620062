export const ruleTypes = {
  SECONDS: 'SECONDS',
  STOPS: 'STOPS',
  RESETS: 'RESETS',
  LAPS: 'LAPS',
};

export const forceType = {
  STOPS: 'STOPS',
  LAPS: 'LAPS',
  EITHER: 'EITHER',
};

export const advancedTypes = {
  ADDITION: 'ADDITION',
  SPREAD: 'SPREAD',
};

export const stackPicks = {
  TOP: 'top',
  BOTTOM: 'bottom',
};
