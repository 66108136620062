import React from 'react';
import { View, TouchableOpacity } from 'react-native-web';

const DoubleTapLink = ({
  children,
  onClick = () => {},
  onDoubleClick,
  style,
}) => {
  let tapTime = new Date().getTime();
  let tapCount = 0;

  return (
    <View
      style={style}
      onClick={() => {
        const delta = new Date().getTime() - tapTime;
        if (delta < 500) {
          if (tapCount < 1) {
            tapCount++;
          } else {
            if (tapCount == 1) {
              onDoubleClick();
            }
            tapCount = 0;
          }
        } else {
          tapCount = 0;
          onClick();
        }
        tapTime = new Date().getTime();
      }}
    >
      {children}
    </View>
  );
};

export default DoubleTapLink;
