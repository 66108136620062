import React from 'react'
import {
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native-web'
import { colors } from '../style/common'
import { connect } from 'react-redux'
import { deletePreset, editPreset, selectPreset } from '../redux/states/app'
import { useHistory } from 'react-router-dom'
import { FiMoreVertical } from 'react-icons/fi'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import AlertDialogSlide from './Alert'
import { forceType, ruleTypes } from '../config/enum'

const options = ['Edit', 'Delete']
const ITEM_HEIGHT = 48

const PresetTile = ({
  item,
  select,
  index,
  remove,
  edit,
  display,
  user,
  light,
}) => {
  let history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = option => {
    console.log(option)
    switch (option) {
      case 'Delete':
        setIsOpen(true)
        break
      case 'Edit':
        edit({ item, index })
        history.push('/presets/edit')
        break
    }
    setAnchorEl(null)
  }
  console.log('Item ------------- ', item, display)
  const MyMenu = () => (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={() => handleClose(null)}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 200,
        },
      }}
    >
      {options.map((option, index) => (
        <MenuItem
          style={{ color: index == 1 ? 'red' : 'white' }}
          key={option}
          onClick={() => handleClose(option)}
        >
          {option}
        </MenuItem>
      ))}
    </Menu>
  )
  const renderPresetMessage = () => {
    let message = ''
    switch (item.forceRule) {
      case ruleTypes.SECONDS:
        message = `Force after ${item.forceValue} seconds`
        break
      case ruleTypes.STOPS:
        message = `Force after ${item.forceValue} stops`
        break
      case ruleTypes.RESETS:
        message = `Force after ${item.forceValue} resets`
        break
      case ruleTypes.LAPS:
        message = `Force after ${item.forceValue} laps`
        break
    }
    switch (item.forceType) {
      case forceType.STOPS:
        message = `${message} - Force on Stop`
        break
      case forceType.LAPS:
        message = `${message} - Force on Lap`
        break
      case forceType.EITHER:
        message = `${message} - Force on Stop or Lap`
        break
    }
    return message
  }

  console.log(renderPresetMessage(), colors.text, colors.background)
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (!display) {
          select({ index })
        }
        console.log('clicked select preset')
      }}
    >
      <View
        style={{
          width: '100%',
          padding: 16,
          paddingRight: 0,
          borderColor: item.selected
            ? colors.activeColor
            : colors.inactiveColor,
          borderWidth: 1,
          marginVertical: 8,
        }}
      >
        <TouchableOpacity>
          <AlertDialogSlide
            open={isOpen}
            confirm={confirm => {
              if (confirm) {
                remove({ index, uid: user.uid, user })
              }
              setIsOpen(false)
            }}
            title={'Delete Preset'}
            message={'Are you sure you want to delete this preset'}
          />
        </TouchableOpacity>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              color: !light ? colors.text : colors.background,
              fontWeight: item.selected ? 'bold' : 'normal',
            }}
          >
            {item.name}
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={{
                padding: 8,
                backgroundColor: colors.inactiveColor,
                marginRight: 8,
                color: !light ? colors.text : colors.background,
              }}
            >
              {item.type}
            </Text>
            {!display && (
              <TouchableOpacity>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <FiMoreVertical
                    size={24}
                    color={!light ? colors.text : colors.background}
                  />
                </IconButton>
              </TouchableOpacity>
            )}
            {!display && (
              <TouchableOpacity>
                <MyMenu />
              </TouchableOpacity>
            )}
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: 8,
            paddingRight: 8,
          }}
        >
          <Text
            style={{
              fontWeight: item.selected ? 'bold' : 'normal',
              color: !light ? colors.text : colors.background,
            }}
          >
            {`${item.value}`}
          </Text>
          <Text
            style={{
              color: !light ? colors.text : colors.background,
              marginHorizontal: 8,
            }}
          >
            {renderPresetMessage()}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default connect(
  state => ({
    user: state.auth.user,
  }),
  dispatch => ({
    select: payload => dispatch(selectPreset(payload)),
    remove: payload => dispatch(deletePreset(payload)),
    edit: payload => dispatch(editPreset(payload)),
  }),
)(PresetTile)
