import React, { useEffect, useState } from "react"
import {
  Dimensions,
  Picker,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native-web"
import { useLocation } from "react-router-dom"
import { connect } from "react-redux"
import InputTags from "../component/inputTags"
import MuiAlert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"
import { updateCurrentPresetInDB, updateSettings2 } from "../redux/states/app"

import { getEmailById, setSettings } from "../services/firebase"
import SwipeView from "../component/SwipeView"
import PresetTile from "../component/PresetTile"
import { ruleTypes } from "../config/enum"
import { colors } from "../style/common"

const { height, width } = Dimensions.get("window")

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const InputPage = ({ settings, updateSettings2 }) => {
  const [value, setValue] = useState("")
  const [defaultValue, setDefaultValue] = useState([])
  const [open, setOpen] = React.useState(false)
  const [preset, setPreset] = useState(null)
  const [errorMessage, setErrorMessage] = React.useState("initState")
  const [email, setEmail] = useState("")
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const id = queryParams.get("id")

  useEffect(() => {
    async function getEmail() {
      const email = await getEmailById(id)
      setEmail(email)
    }

    getEmail()
  }, [id])

  console.log(preset)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <View style={{ padding: 20 }}>
      <View style={{ height: height / 2.2 }}>
        <View style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <InputTags
            onChange={v => {
              setValue(v)
            }}
            focused
            defaultValue={defaultValue}
          />
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              marginBottom: 16,
            }}
          >
            <TouchableOpacity
              disabled={value.length < 1}
              style={{
                backgroundColor: "#0176FF",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: 16,
              }}
              onPress={() => {
                if (value.filter(v => v.length > 2).length > 0) {
                  setOpen(true)
                  setErrorMessage(
                    "Values with more than 2 figures can't be set for millisecond",
                  )
                } else {
                  if (value.length > 0) {
                    const data1 = {
                      ...{
                        ...settings,
                        checking: [],
                      },
                      type: "MS",
                      selected: true,
                      value,
                    }
                    setPreset(data1)
                    updateSettings2(data1)
                    setDefaultValue([])
                    setSettings(data1, email)
                  } else {
                    alert(
                      "You have to set at least one value press comma to validate",
                    )
                  }
                }
              }}
            >
              <Text style={{ color: "white", fontSize: 20 }}>MS</Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={value.length < 1}
              style={{
                backgroundColor: "#54C242",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: 16,
              }}
              onPress={() => {
                if (value.length > 0) {
                  const data2 = {
                    ...{
                      ...settings,
                      checking: [],
                      totalSettings: [],
                      forceType: settings.totalForceType,
                      keepForce:
                        value.length > 1 ? false : totalSettings[0]?.checked,
                      tapOnLap:
                        value.length > 1 ? false : totalSettings[2]?.checked,
                      showOnTap:
                        value.length > 1 ? false : totalSettings[1]?.checked,
                    },
                    type: "TO",
                    selected: true,
                    value,
                  }
                  setPreset(data2)
                  updateSettings2(data2)
                  setSettings(data2, email)
                  setDefaultValue([])

                  // history.goBack()
                } else {
                  alert(
                    "You have to set at least one value press comma to validate",
                  )
                }
              }}
            >
              <Text style={{ color: "white", fontSize: 20 }}>Total</Text>
            </TouchableOpacity>
            <TouchableOpacity
              // disabled={value.length != 1}
              style={{
                backgroundColor: "#F28501",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: 16,
              }}
              onPress={() => {
                if (value.length > 0) {
                  const data3 = {
                    ...{ ...settings, checking: [] },
                    type: "FT",
                    selected: true,
                    value,
                  }
                  setPreset(data3)
                  updateSettings2(data3)
                  setDefaultValue([])

                  setSettings(data3, email)
                } else {
                  alert(
                    "You have to set at least one value press comma to validate",
                  )
                }
              }}
            >
              <Text style={{ color: "white", fontSize: 20 }}>FT</Text>
            </TouchableOpacity>
          </View>
        </View>
        {preset && (
          <SwipeView
            style={{
              position: "absolute",
              opacity: 1,
              width: "100%",
            }}
          >
            <PresetTile item={preset} display light={false} />
          </SwipeView>
        )}
      </View>
      <View
        style={{
          flexDirection: "row",
          borderColor: "white",
          borderTopWidth: 2,
          paddingTop: 8,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            style={{
              color: colors.text,
              marginRight: 20,
            }}
          >
            Force after:
          </Text>
          <TextInput
            style={{
              color: colors.text,
              backgroundColor: colors.background,
              borderColor: colors.text,
              borderWidth: 1,
              borderRadius: 3,
              padding: 8,
              margin: 8,
              width: width / 4,
            }}
            keyboardType={"numeric"}
            placeholder={"Number"}
            defaultValue={settings.forceValue}
            onChangeText={value => {
              const data = {
                // uid: user.uid,
                ...preset,
                forceValue: value,
              }
              updateSettings2(data)
              setSettings(data, email)
            }}
          />
        </View>
        <Picker
          style={{
            color: colors.text,
            backgroundColor: colors.background,
            borderColor: colors.text,
            borderWidth: 1,
            borderRadius: 3,
            padding: 8,
            margin: 8,
          }}
          onValueChange={value => {
            const data = {
              // uid: user.uid,
              ...preset,
              forceRule: value.toUpperCase(),
            }
            updateSettings2(data)
            setSettings(data, email)
          }}
          selectedValue={
            settings.forceRule ? settings.forceRule.toLowerCase() : null
          }
        >
          {Object.keys(ruleTypes).map(item => (
            <Picker.Item label={item.toLowerCase()} />
          ))}
        </Picker>
      </View>
      <Snackbar
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </View>
  )
}

export default connect(
  state => {
    return {
      preset: state.app.currentPreset,
      presets: state.app.presets,
      settings: state.app.settings || {},
    }
  },
  dispatch => ({
    updateSettings2: payload => dispatch(updateSettings2(payload)),

    updateCurrentPresetInDB: payload =>
      dispatch(updateCurrentPresetInDB(payload)),
  }),
)(InputPage)

const totalSettings = [
  { title: "Keep Force", checked: false },
  { title: "Change others on lap tap", checked: false },
  { title: "Tap on Lap to Force", checked: false },
]
