import { forceType, ruleTypes, stackPicks } from "../../config/enum"
import { setData, setStops } from "../../services/firebase"
import Mnemonica from "./../../services/Mnemonica.json"
import Aronson from "./../../services/Aronson.json"
import SiStebbins from "./../../services/Si-Stebbins.json"

export const OPEN_APP = "AppState/OPEN_APP"
export const CLOSE_APP = "AppState/CLOSE_APP"
export const UPDATE_SETTINGS = "AppState/UPDATE_SETTINGS"
export const UPDATE_SETTINGS2 = "AppState/UPDATE_SETTINGS2"
export const UPDATE_PRESETS = "AppState/UPDATE_PRESETS"
export const SELECT_PRESET = "AppState/SELECT_PRESET"
export const UPDATE_CURRENT_PRESET = "AppState/UPDATE_CURRENT_PRESET"
export const ADD_PRESET = "AppState/ADD_PRESET"
export const DELETE_PRESET = "AppState/DELETE_PRESET"
export const RESET_PRESET = "AppState/RESET_PRESET"
export const EDIT_PRESET = "AppState/EDIT_PRESET"
export const UPDATE_PRESET = "AppState/UPDATE_PRESET"
export const TOGGLE_VISUAL_HINT = "AppState/TOGGLE_VISUAL_HINT"
export const TOGGLE_RESET_VISUAL_HINT = "AppState/TOGGLE_RESET_VISUAL_HINT"
export const SYNC_DATA = "AppState/SYNC_DATA"
export const LOGIN = "AppState/LOGIN"
export const UPDATE_KEY = "AppState/UPDATE_KEY"
export const CHANGE_THEME = "AppState/CHANGE_THEME"
export const UPDATE_STACKS = "AppState/UPDATE_STACKS"
export const UPDATE_SELECTED_STACK = "AppState/UPDATE_SELECTED_STACK"
export const UPDATE_LOGS = "AppState/UPDATE_LOGS"
export const CLEAR_LOGS = "AppState/CLEAR_LOGS"

export const initialState = {
  opened: false,
  settings: {
    checking: [
      { title: "Long Press on Start to Force", checked: false },
      { title: "Long Press on Reset to Reset", checked: false },
      { title: "Double Swipe up to see preset", checked: false },
      { title: "Visual hint", checked: false },
    ],
    totalSettings: [
      { title: "Keep Force", checked: false },
      { title: "Change others on lap tap", checked: false },
      { title: "Tap on Lap to Force", checked: false },
    ],
    forceType: forceType.STOPS,
    totalForceType: forceType.STOPS,
    forceValue: 5,
    forceRule: ruleTypes.SECONDS,
  },
  presets: [],
  stacks: [
    { name: "Mnemonica", stack: Mnemonica },
    { name: "Aronson", stack: Aronson },
    { name: "Si-Stebbins", stack: SiStebbins },
  ],
  selectedStack: 0,
  logs: [],
  currentPreset: null,
  presetToEdit: null,
  showVisualHint: false,
  showResetVisualHint: false,
  loggedIn: false,
  dateLogin: new Date(),
  token: "null",
  activationKey: "null",
  remainingTimes: 0,
  useComma: true,
  stackMode: "TO",
  stackPick: stackPicks.TOP,
  theme: window.navigator.platform == "iPhone" ? "iOS13" : "androidSamsung",
}

export const openApp = () => {
  return {
    type: OPEN_APP,
  }
}

export const closeApp = () => {
  return {
    type: CLOSE_APP,
  }
}

export const toggleVisualHint = payload => {
  return {
    type: TOGGLE_VISUAL_HINT,
    payload,
  }
}

export const toggleResetVisualHint = payload => {
  return {
    type: TOGGLE_RESET_VISUAL_HINT,
    payload,
  }
}

export const updateSettings = payload => {
  return {
    type: UPDATE_SETTINGS,
    payload,
  }
}
export const updateSettings2 = payload => {
  return {
    type: UPDATE_SETTINGS2,
    payload,
  }
}

export const updatePresets = payload => {
  return {
    type: UPDATE_PRESETS,
    payload,
  }
}

export const selectPreset = payload => {
  return {
    type: SELECT_PRESET,
    payload,
  }
}

export const updateCurrentPreset = payload => {
  return {
    type: UPDATE_CURRENT_PRESET,
    payload,
  }
}

export const updateCurrentPresetInDB = payload => {
  return {
    type: UPDATE_CURRENT_PRESET,
    payload: payload.data,
  }
}

export const addPreset = payload => {
  return {
    type: ADD_PRESET,
    payload,
  }
}

export const bulkAddPreset = payload => {
  return {
    type: ADD_PRESET,
    payload,
  }
}

export const editPreset = payload => {
  return {
    type: EDIT_PRESET,
    payload,
  }
}

export const updatePreset = payload => {
  return {
    type: UPDATE_PRESET,
    payload,
  }
}

export const resetPreset = payload => {
  return {
    type: RESET_PRESET,
    payload,
  }
}

export const deletePreset = payload => {
  return {
    type: DELETE_PRESET,
    payload,
  }
}

export const syncData = payload => {
  return {
    type: SYNC_DATA,
    payload,
  }
}

export const login = () => {
  return {
    type: LOGIN,
  }
}

export const updateKeys = payload => {
  return {
    type: UPDATE_KEY,
    payload,
  }
}

export const changeTheme = payload => {
  return {
    type: CHANGE_THEME,
    payload,
  }
}

export const updateStacks = payload => {
  return {
    type: UPDATE_STACKS,
    payload,
  }
}

export const updateSelectedStack = payload => {
  return {
    type: UPDATE_SELECTED_STACK,
    payload,
  }
}

export const updateLogs = payload => {
  setStops(payload)

  return {
    type: UPDATE_LOGS,
    payload,
  }
}

export const bulkUpdateLogs = payload => {
  return {
    type: UPDATE_LOGS,
    payload,
  }
}
export const clearLogs = () => {
  return {
    type: CLEAR_LOGS,
  }
}

export default function(state = initialState, action) {
  // console.log("State and Action ----- ", state, action)

  switch (action.type) {
    case OPEN_APP:
      return { ...state, opened: true }
    case CLOSE_APP:
      return { ...state, opened: false }
    case UPDATE_SETTINGS:
      const settings = { ...action.payload }
      delete settings.uid
      const newSettings = {
        ...state,
        settings,
      }
      setData(
        {
          ...newSettings,
          email: action.payload.user.email,
          displayName: action.payload.user.displayName,
        },
        action.payload.uid,
      ).then(r => {})
      return newSettings
    case UPDATE_SETTINGS2:
      const setting = { ...action.payload }
      const newSetting = {
        ...state,
        settings: setting,
      }

      return newSetting
    case UPDATE_PRESETS:
      const presets = { ...action.payload }
      delete presets.uid
      const newPresets = {
        ...state,
        presets,
      }
      setData(
        {
          ...newPresets,
          email: action.payload.user.email,
          displayName: action.payload.user.displayName,
        },
        action.payload.uid,
      ).then(r => {})
      return newPresets
    case ADD_PRESET:
      const addedPreset = { ...action.payload }
      delete addedPreset.uid
      const newAddedPreset = {
        ...state,
        presets: [
          ...state.presets.map(i => ({ ...i, selected: false })),
          { ...addedPreset, selected: true },
        ],
        currentPreset: { ...addedPreset, selected: true },
      }
      setData(
        {
          ...newAddedPreset,
          email: action.payload?.user?.email,
          displayName: action.payload?.user?.displayName,
        },
        action.payload?.uid,
      ).then(r => {})
      return newAddedPreset
    case EDIT_PRESET:
      const newEditedPreset = {
        ...state,
        presetToEdit: action.payload,
      }
      return newEditedPreset
    case UPDATE_PRESET:
      const newItem = { ...action.payload }
      delete newItem.uid
      const newUpdatedPresets = {
        ...state,
        presets: state.presets.map((element, index) =>
          index == newItem.index
            ? { ...newItem.item, selected: true }
            : { ...element, selected: false },
        ),
        presetToEdit: null,
        currentPreset: newItem.item.selected
          ? newItem.item
          : state.currentPreset,
      }
      setData(
        {
          ...newUpdatedPresets,
          email: action.payload.user.email,
          displayName: action.payload.user.displayName,
        },
        action.payload.uid,
      ).then(r => {})
      return newUpdatedPresets
    case DELETE_PRESET:
      const newDeletedItem = { ...action.payload }
      delete newDeletedItem.uid
      const newDeletedPreset = {
        ...state,
        presets: state.presets.filter(
          (item, index) => newDeletedItem.index != index,
        ),
      }
      setData(
        {
          ...newDeletedPreset,
          email: action.payload.user.email,
          displayName: action.payload.user.displayName,
        },
        action.payload.uid,
      ).then(r => {})
      return newDeletedPreset
    case RESET_PRESET:
      return {
        ...state,
        currentPreset: state.presets.filter(item => item.selected)[0],
      }
    case UPDATE_CURRENT_PRESET:
      const currentPreset = action.payload
      const newCurrentPresets = {
        ...state,
        currentPreset,
      }
      return newCurrentPresets
    case SELECT_PRESET:
      const { payload } = action
      const newPreset = state.presets.map((item, index) => ({
        ...item,
        selected: index == payload.index,
      }))
      return {
        ...state,
        presets: newPreset,
        currentPreset: state.presets[payload.index],
      }
    case TOGGLE_VISUAL_HINT:
      return {
        ...state,
        showVisualHint: state.settings.checking[3]?.checked
          ? action.payload.showVisualHint
          : state.showVisualHint,
      }
    case TOGGLE_RESET_VISUAL_HINT:
      return {
        ...state,
        showResetVisualHint: state.settings.checking[1]?.checked
          ? action.payload.showResetVisualHint
          : state.showResetVisualHint,
      }
    case SYNC_DATA:
      const newData = action.payload.data
      if (!newData?.stacks) {
        newData.stacks = [
          { name: "Mnemonica", stack: Mnemonica },
          { name: "Aronson", stack: Aronson },
          { name: "Si-Stebbins", stack: SiStebbins },
        ]
      }
      setData(
        {
          ...state,
          ...newData,
          email: action.payload.user.email,
          displayName: action.payload.user.displayName,
        },
        action.payload.user.uid,
      )
        .then(r => {})
        .catch(e => {
          console.log(e)
        })
      return { ...state, ...action.payload.data }
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
      }
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      }
    case UPDATE_KEY:
      return {
        ...state,
        dateLogin: action.payload.dateLogin,
        activationKey: action.payload.activationKey,
        token: action.payload.token,
        remainingTimes: action.payload.remainingTimes,
      }
    case UPDATE_STACKS:
      return {
        ...state,
        stacks: action.payload,
      }
    case UPDATE_SELECTED_STACK:
      return {
        ...state,
        selectedStack: action.payload,
      }
    case UPDATE_LOGS:
      let newLogs = [...(state.logs || [])]
      if (state.logs?.length === 10) {
        newLogs = newLogs.slice(1)
      }
      // delete action.payload.email;
      newLogs.push(action.payload)
      return {
        ...state,
        logs: newLogs,
      }
    case CLEAR_LOGS:
      return {
        ...state,
        logs: [],
      }

    default:
      return state
  }
}
