import React from 'react';
import { StyleSheet, View, Text } from 'react-native-web';
import { advancedTypes, forceType } from '../config/enum';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    color: 'white',
  },
}));
function ForceTypePicker({
  selectedValue,
  onSelect,
  isFullTime,
  disabledItems,
  advancedOptions,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(selectedValue);
  React.useEffect(() => {
    setValue(selectedValue);
    return () => {};
  }, [selectedValue]);

  const handleChange = event => {
    onSelect(event.target.value);
    setValue(event.target.value);
  };
  const typesNames = ['Stop', 'Lap', 'Either'];
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Text style={styles.text}>Force on: </Text>
      <RadioGroup
        style={{ flexDirection: 'row' }}
        aria-label="gender"
        name="gender2"
        value={value}
        onChange={handleChange}
        className={classes.formControl}
      >
        {Object.keys(forceType).map((item, i) => (
          <FormControlLabel
            disabled={
              (item != forceType.STOPS && isFullTime) ||
              (disabledItems && disabledItems.includes(item))
            }
            value={item}
            control={<Radio color="primary" />}
            label={typesNames[i]}
            labelPlacement="start"
          />
        ))}
      </RadioGroup>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    color: 'white',
    paddingVertical: 16,
  },
});

export default ForceTypePicker;
