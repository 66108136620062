import React from 'react'

const WithAuthProtection = redirectPath => WrappedComponent => {
  const WithAuthProtection = props => {
    // const { me } = props;
    const saved = localStorage.getItem('user')
    const me = saved ? JSON.parse(saved) : null
    const { history } = props

    React.useEffect(() => {
      if (!me) {
        // no auth at the beginning of the app, redirect them to
        // login.
        return history.replace(redirectPath)
      }
    })

    return me ? <WrappedComponent {...props} /> : null
  }

  return WithAuthProtection
}

export default WithAuthProtection
