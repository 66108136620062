import { Dimensions } from "react-native-web"

const { width } = Dimensions.get("screen")

const colors = {
  navBarContent: "#fffff",
  navBarBackground: "#ffffff",
  text: "#000000",
  buttonText: "#ffffff",
  activeColor: "#6364D7",
  inactiveColor: "#7D7D7D",
  background: "#F2F2F2",
  startColor: "#6364D7",
  lapColor: "#000000",
  stopColor: "#ED4965",
}
export default {
  colors,
  actionButton: {
    opacity: {
      opacity: 1,
    },
    button: {
      width: width / 4,
      height: width / 9,
      borderRadius: width / 18,
      justifyContent: "center",
      alignItems: "center",
    },
    textHolder: {
      position: "absolute",
      width: width / 4,
      height: width / 8,
      justifyContent: "center",
      alignItems: "center",
      zIndex: 90,
    },
    border: {
      width: width / 4,
      height: width / 9,
      borderRadius: width / 18,
      borderWidth: 0,
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      width: width / 4,
      height: width / 9,
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: width / 25,
      fontWeight: "500",
      color: colors.buttonText,
    },
  },
  container: {
    backgroundColor: colors.background,
  },
  chrono: {
    container: {
      paddingHorizontal: 16,
      width,
      height: 130,
    },
    layout: {
      flexDirection: "column-reverse",
      flex: 10,
      alignItems: "center",
    },
    layoutWithLaps: {
      flexDirection: "column-reverse",
      flex: 13,
    },
    lapsContainer: {
      flex: 10,
      marginBottom: 10,
    },
    lapsContainerNoEmpty: {
      marginBottom: 10,
      flex: 13,
    },
    lapList: {},
    text: {
      fontSize: "12vw",
      width: "9vw",
      fontWeight: "200",
      color: colors.text,
      // backgroundColor: 'pink',
      textAlign: "center",
      // fontFamily: 'SF Pro Display',
    },
    textLap: {
      // fontFamily: 'SF Pro Display',
      fontSize: width * 0.07,
      fontWeight: "200",
      color: colors.inactiveColor,
    },
    textContainer: {
      justifyContent: "flex-end",
      alignItems: "center",
      flex: 10,
    },
    textContainerWithLaps: {
      justifyContent: "center",
      alignItems: "center",
      flex: 10,
    },
    textWrapper: {
      width: "63vw",
      flexDirection: "row",
      alignItems: "center",
      // backgroundColor: 'red',
    },
    textWrapperLap: {
      width: "34%",
      flexDirection: "row",
      alignItems: "center",
    },
    small:
      window.navigator.platform == "iPhone"
        ? { width: width * 0.3 }
        : { width: width * 0.2 },
    smallLap:
      window.navigator.platform == "iPhone"
        ? { width: width * 0.13 }
        : { width: width * 0.1 },
    big: { flexDirection: "row" },
    bigLap: { flexDirection: "row" },
    actionButtons: {
      flexDirection: "row-reverse",
      justifyContent: "space-around",
      width: width - 32,
      height: width / 5,
      alignItems: "center",
      zIndex: 90,
    },
  },
  lapTile: {
    text: {
      fontSize: width * 0.04,
      color: colors.text,
      textAlign: "left",
      paddingVertical: 12,
    },
    textLap: {
      fontSize: width * 0.04,
      color: colors.inactiveColor,
      textAlign: "left",
      paddingVertical: 12,
    },
    container: {
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  tabs: {
    container: {
      flexDirection: "row",
      width: "100vw",
      justifyContent: "space-around",
      backgroundColor: colors.background,
    },
    doubleClick: {
      height: 130,
      // flex: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: 8,
      paddingBottom: 24,
      paddingTop: 4,
      backgroundColor: colors.background,
      "touch-action": "manipulation",
    },
    tabTitleText: {
      color: "#7D7D7D",
      fontWeight: "600",
      fontSize: "3.5vw",
    },
    selectedTabTitleText: {
      color: colors.activeColor,
      fontWeight: "600",
      fontSize: "3.5vw",
      borderBottomWidth: 2,
      borderBottomStyle: "dotted",
      borderBottomColor: colors.activeColor,
    },
  },
}
