import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "./redux/store"
import { BrowserRouter as Router } from "react-router-dom"
import { View } from "react-native-web"
import { colors } from "./style/common"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import AuthLoading from "./pages/AuthLoading"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#000",
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.activeColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
})

function App() {
  const [height, setHeight] = useState(getWindowSize().innerHeight)

  function getWindowSize() {
    const { innerHeight } = window
    return { innerHeight }
  }

  useEffect(() => {
    function handleWindowResize() {
      const size = getWindowSize()
      setHeight(size.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <View
              style={{
                flex: 1,
                backgroundColor: colors.background,
                height: height,
              }}
              id={"body-container"}
            >
              {/* <ThemeProvider theme={darkTheme}> */}
              <AuthLoading />
              {/* </ThemeProvider> */}
            </View>
          </Router>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  )
}

export default App
