import React, { useState } from "react"
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native-web"
import tellSign from "zodiac-sign-teller"
import { colors } from "../style/common"
import { IoIosClose } from "react-icons/io"
import { useHistory } from "react-router-dom"
import Button from "@material-ui/core/Button"
import RadioButton from "../component/RadioButton"
import { useSelector } from "react-redux"
import moment from "moment"

function Logs({}) {
  let history = useHistory()
  const [mode, setMode] = useState("Birthday")
  const [date, setDate] = useState(null)
  const logs = useSelector(state => state.app?.logs || [])
  const stacks = useSelector(state => state?.app?.stacks || [])

  const [day, setDay] = useState(null)
  const [month, setMonth] = useState(null)
  const [card, setCard] = useState(null)
  const addZero = n => {
    const parsedN = parseInt(`${n}`)
    return parsedN < 10 ? "0" + parsedN : parsedN
  }
  const onValueSelected = value => {
    if (mode === "Birthday") {
      if (value > 31) {
        alert("Invalid Date")
        return
      }
      if (!day) {
        setDay(Number(value))
      } else if (!month) {
        try {
          const momentDate = moment(
            `${new Date().getFullYear()}-${addZero(value)}-${addZero(day)}`,
            "YYYY-MM-DD",
            true,
          )
          const date = momentDate.toDate()
          if (date.toISOString() !== "Invalid Date") {
            setMonth(Number(value))
            setDate(date)
          } else {
            alert("Invalid Date")
          }
        } catch (e) {
          alert("Invalid Date")
        }
      } else {
        setDay(Number(value))
        setMonth(null)
        setDate(null)
      }
    }
    if (mode === "Stack") {
      if (value > 0 && value < 53) {
        setCard(Math.round(value))
      } else {
        alert("Invalid Position")
      }
    }
  }

  const getDaysPassed = () => {
    if (date) {
      if (new Date() > date) {
        let diff = Math.ceil((new Date() - date) / (1000 * 60 * 60 * 24))
        diff = diff > 365 ? 365 - getDaysComing().diff : diff

        return {
          diff,
          day: moment(date).format("dddd"),
        }
      } else {
        const newDate = new Date(date.toLocaleDateString())
        newDate.setFullYear(date.getFullYear() - 1)

        let diff = Math.ceil((new Date() - newDate) / (1000 * 60 * 60 * 24))
        diff = diff > 365 ? 365 - getDaysComing().diff : diff
        return {
          diff,
          day: moment(newDate).format("dddd"),
        }
      }
    } else {
      return null
    }
  }
  const getDaysComing = () => {
    if (date) {
      if (date > new Date()) {
        let diff = Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24))
        diff =
          diff > 365 || diff + getDaysPassed().diff < 365
            ? 365 - getDaysPassed().diff
            : diff
        return {
          diff,
          day: moment(date).format("dddd"),
        }
      } else {
        const newDate = new Date(date.toLocaleDateString())
        newDate.setFullYear(date.getFullYear() + 1)
        let diff = Math.ceil((newDate - new Date()) / (1000 * 60 * 60 * 24))
        diff =
          diff > 365 || diff + getDaysPassed().diff < 365
            ? 365 - getDaysPassed().diff
            : diff
        return {
          diff,
          day: moment(newDate).format("dddd"),
        }
      }
    } else {
      return null
    }
  }

  console.log(date)

  const calculateDaysPassed = () => {
    if (!date) return
    const currentDate = new Date()
    const birthdateThisYear = new Date(
      currentDate.getFullYear(),
      date.getMonth(),
      date.getDate(),
    )

    if (birthdateThisYear > currentDate) {
      birthdateThisYear.setFullYear(currentDate.getFullYear() - 1)
    }

    const daysPassed = Math.floor(
      (currentDate - birthdateThisYear) / (24 * 60 * 60 * 1000),
    )

    const day = moment(birthdateThisYear).format("dddd")

    console.log("Passed : ", daysPassed)
    return { diff: daysPassed, day }
  }

  const calculateDaysRemaining = () => {
    if (!date) return
    const currentDate = new Date()
    const nextBirthday = new Date(
      currentDate.getFullYear(),
      date.getMonth(),
      date.getDate(),
    )

    if (nextBirthday <= currentDate) {
      nextBirthday.setFullYear(currentDate.getFullYear() + 1)
    }

    const daysRemaining =
      Math.floor((nextBirthday - currentDate) / (24 * 60 * 60 * 1000)) + 1

    const day = moment(nextBirthday).format("dddd")
    console.log("Remaings : ", daysRemaining)
    return { diff: daysRemaining, day }
  }

  return (
    <View style={{ padding: 20, flex: 1 }}>
      <TouchableOpacity
        onPress={() => {
          history.goBack()
        }}
      >
        <IoIosClose color={"white"} size={48} style={{ marginBottom: 20 }} />
      </TouchableOpacity>
      <RadioButton
        defaultValue={"Birthday"}
        options={[
          {
            value: "Birthday",
            label: "Birthday",
          },
          {
            value: "Stack",
            label: "Stack",
          },
        ]}
        onChange={value => {
          setMode(value)
          setMonth(null)
          setDay(null)
        }}
      />
      <View style={{ flex: 1, justifyContent: "center" }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          {mode === "Birthday" ? (
            <Text
              style={{
                color: colors.text,
                fontSize: 24,
              }}
            >
              {addZero(day) || "DD"} /{" "}
              {month ? addZero(Math.round(month)) : "MM"}
            </Text>
          ) : (
            <Text
              style={{
                color: colors.text,
                fontSize: 24,
              }}
            >
              {card}
            </Text>
          )}
        </View>

        <ScrollView>
          <View style={{ borderColor: colors.activeColor, borderWidth: 1 }}>
            {logs.map((e, i) => (
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    textAlign: "center",
                    flex: 1,
                    color: colors.text,
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    fontSize: 20,
                  }}
                >
                  {i === 0 ? "last" : i + 1}
                </Text>
                <View style={styles.timeContainer}>
                  <Text
                    style={styles.timeText}
                    onClick={() =>
                      onValueSelected(logs[logs.length - i - 1].sc)
                    }
                  >
                    {addZero(logs[logs.length - i - 1].sc)}
                  </Text>
                  <Text style={{ paddingHorizontal: 12, color: colors.text }}>
                    :
                  </Text>
                  <Text
                    style={styles.timeText}
                    onClick={() =>
                      onValueSelected(
                        Math.round(logs[logs.length - i - 1].ms / 10),
                      )
                    }
                  >
                    {addZero(Math.round(logs[logs.length - i - 1].ms / 10))}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </ScrollView>
        {!!day && !!month && mode === "Birthday" && (
          <View style={{ flexDirection: "row", width: "100%", marginTop: 20 }}>
            <View
              style={{
                flex: 1,
              }}
            >
              <View
                style={{
                  padding: 12,
                  borderColor: colors.activeColor,
                  borderWidth: 1,
                }}
              >
                <Text style={{ color: "white" }}>
                  {tellSign().getSignByDate({
                    day: addZero(day),
                    month: addZero(month),
                  })}
                </Text>
              </View>
            </View>
            <View style={{ padding: 10 }} />
            <View
              style={{
                flex: 1,
              }}
            >
              <View
                style={{
                  padding: 12,
                  borderColor: colors.activeColor,
                  borderWidth: 1,
                  marginBottom: 10,
                }}
              >
                <Text style={{ color: "white" }}>
                  P: {calculateDaysPassed()?.day}, {calculateDaysPassed()?.diff}{" "}
                </Text>
              </View>
              <View
                style={{
                  padding: 12,
                  borderColor: colors.activeColor,
                  borderWidth: 1,
                }}
              >
                <Text style={{ color: "white" }}>
                  N: {calculateDaysRemaining()?.day},{" "}
                  {calculateDaysRemaining()?.diff}
                </Text>
              </View>
            </View>
          </View>
        )}
        {!!card && mode === "Stack" && (
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              marginTop: 20,
              flexWrap: "wrap",
            }}
          >
            {stacks.map((e, i) => (
              <>
                <View
                  style={{
                    width: "48%",
                    padding: 12,
                    borderColor: colors.activeColor,
                    borderWidth: 1,
                    marginBottom: 10,
                  }}
                >
                  <Text style={{ color: "white" }}>
                    {e.name}: {e.stack[card - 1]}
                  </Text>
                </View>
                {i % 2 === 0 && <View style={{ width: "4%" }} />}
              </>
            ))}
          </View>
        )}
      </View>
      <View>
        <Button
          style={{ flex: 1, marginTop: 16 }}
          variant="contained"
          color="primary"
          onClick={() => {
            setDay(null)
            setMonth(null)
            setCard(null)
          }}
        >
          Reset
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  timeText: {
    color: colors.text,
    fontSize: 20,
  },
  timeContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    flex: 2,
    borderColor: colors.activeColor,
    borderLeftWidth: 1,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
})

export default Logs
