import React from "react"
import ReactDOM from "react-dom"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

import "./index.css"
import App from "./App"
import "./i18n"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/browser"
import { colors } from "./style/common"

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      default: "#000",
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.activeColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
})

Sentry.init({
  dsn:
    "https://32e702312978422aa02d1341821889ae@o394048.ingest.sentry.io/5243731",
})

ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
    <App />
  </ThemeProvider>,

  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
document.onvisibilitychange = e => {
  let refreshBar = document.querySelector("#refresh-bar")
  if (refreshBar && !document.hidden) {
    serviceWorker.forceSWupdate()
  }
}
serviceWorker.register()
