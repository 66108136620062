import React from "react"
import { Route, Switch } from "react-router-dom"
import Home from "../pages/Home"
import Settings from "../pages/Settings"
import Presets from "../pages/Presets"
import AddPreset from "../pages/AddPreset"
import Input from "../pages/Input"
import Login from "../pages/Login"
import ForgetPassword from "../pages/ForgetPassword"
import withAuthProtection from "../component/withAuthProtection"
import KeyCheck from "../pages/KeyCheck"
import AuthLoading from "../pages/AuthLoading"
import SecondInput from "../pages/SecondInput"
import Logs from "../pages/Logs"
import LogsUpload from "../pages/LogsUpload"
import InputUpload from "../pages/InputUpload"

const ProtectedHome = withAuthProtection("/login")(Home)
const ProtectedSettings = withAuthProtection("/login")(Settings)
const ProtectedPresets = withAuthProtection("/login")(Presets)
const ProtectedAddPreset = withAuthProtection("/login")(AddPreset)
const ProtectedInput = withAuthProtection("/login")(Input)
const ProtectedSecondInput = withAuthProtection("/login")(SecondInput)
const ProtectedLogs = withAuthProtection("/login")(Logs)

const Routes = ({ user, valid }) => {
  React.useEffect(() => {
    console.log(user, valid)
    return () => {
      console.log(user)
    }
  }, [user, valid])

  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/forget-password">
        <ForgetPassword />
      </Route>
      <Route path="/check-key">
        <KeyCheck />
      </Route>
      <Route path="/logsupload">
        <LogsUpload />
      </Route>
      <Route path="/inputupload">
        <InputUpload />
      </Route>
      <Route
        exact
        path="/"
        render={prop => <ProtectedHome me={user && valid} {...prop} />}
      />
      <Route exact path="/" render={prop => <AuthLoading />} />

      <Route
        path="/settings"
        exact
        render={props => <ProtectedSettings me={user && valid} {...props} />}
      />
      <Route
        exact
        path="/presets"
        render={props => <ProtectedPresets me={user && valid} {...props} />}
      />
      <Route
        exact
        path="/presets/add"
        render={props => <ProtectedAddPreset me={user && valid} {...props} />}
      />
      <Route
        isLoggedIn={user != null}
        exact
        path="/presets/edit"
        render={props => <ProtectedAddPreset me={user && valid} {...props} />}
      />
      <Route
        isLoggedIn={user != null}
        path="/input"
        render={props => <ProtectedInput me={user && valid} {...props} />}
      />

      <Route
        isLoggedIn={user != null}
        path="/second-input"
        render={props => <ProtectedSecondInput me={user && valid} {...props} />}
      />
      <Route
        isLoggedIn={user != null}
        path="/logs"
        render={props => <ProtectedLogs me={user && valid} {...props} />}
      />
    </Switch>
  )
}

export default Routes
