import * as firebase from "firebase/app"

import "firebase/auth"
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCPkzeQhnQUku74baPFPGdXPwr69MPvstA",
  authDomain: "chrono-force.firebaseapp.com",
  databaseURL: "https://chrono-force.firebaseio.com",
  projectId: "chrono-force",
  storageBucket: "chrono-force.appspot.com",
  messagingSenderId: "85295059347",
  appId: "1:85295059347:web:b8f008d6d8a37553afd866",
  measurementId: "G-SYYKS1T8P3",
}

firebase.initializeApp(firebaseConfig)

export const signInGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

export const signUpWithEmail = async (email, password) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password)
}

export const loginEmail = async (email, password) => {
  return firebase.auth().signInWithEmailAndPassword(email, password)
}

export const logout = async () => {
  return firebase.auth().signOut()
}

export const getCurrentUser = () => {
  return firebase.auth().currentUser
}

export const setData = async (data, uid) => {
  try {
    return firebase
      .firestore()
      .collection("appData")
      .doc(uid)
      .set(data)
      .then(r => {
        console.log("updateDATA", r)
      })
      .catch(e => {
        console.log("updateDATA", e)
      })
  } catch (e) {
    console.log("updateDATA", e)
  }
}

export const setStops = async data => {
  data.timestamp = Date.now()
  try {
    const collectionRef = firebase.firestore().collection("stops")

    // Fetch the documents for the user ordered by timestamp descending
    const querySnapshot = await collectionRef
      .where("email", "==", data.email)
      .orderBy("timestamp", "desc")
      .get()

    // Skip the first 10 documents and delete the rest
    const docsToDelete = querySnapshot.docs.slice(10)

    for (const doc of docsToDelete) {
      await collectionRef.doc(doc.id).delete()
    }
    return firebase
      .firestore()
      .collection("stops")
      .add(data)
  } catch (e) {
    console.log("error : ", e)
  }
}

export const setSettings = async (data, email) => {
  data.timestamp = Date.now()
  try {
    return firebase
      .firestore()
      .collection("settings")
      .doc(email)
      .set(data)
      .then(r => {
        console.log("Inserted -----")
      })
  } catch (e) {
    console.log("error : ", e)
  }
}

export const getSettings = async email => {
  return firebase
    .firestore()
    .collection("settings")
    .doc(email)
    .get()
}

export const getData = async uid => {
  return firebase
    .firestore()
    .collection("appData")
    .doc(uid)
    .get()
}

export const getKeys = async uid => {
  return firebase
    .firestore()
    .collection("keys")
    .doc(uid)
    .get()
}

export const getStops = async email => {
  return firebase
    .firestore()
    .collection("stops")
    .doc(email)
    .get()
}

export const getStopRef = () => {
  return firebase.firestore().collection("stops")
}

export const getSettingsRef = id => {
  return firebase
    .firestore()
    .collection("settings")
    .doc(id)
}
export const removeSetting = async id => {
  const documentRef = firebase
    .firestore()
    .collection("settings")
    .doc(id)
  await documentRef.delete()
  console.log("Document remove successfully")
}

export const getStopsByEmail = async email => {
  try {
    const collectionRef = firebase.firestore().collection("stops")
    const querySnapshot = await collectionRef
      .where("email", "==", email)
      .orderBy("timestamp", "desc")
      .limit(10)
      .get()

    const documents = []
    querySnapshot.forEach(doc => {
      documents.push(doc.data())
    })
    return documents
  } catch (error) {
    console.error("Error fetching documents:", error)
  }
}

export const getEmailById = async id => {
  try {
    const collectionRef = firebase.firestore().collection("uniqueIds")
    const querySnapshot = await collectionRef
      .where("userId", "==", Number(id))
      .limit(1)
      .get()

    const doc = querySnapshot.docs[0]
    // Document found, you can access its data using doc.data()
    return doc.id
  } catch (error) {
    console.error("Error fetching documents:", error)
  }
}

export const setUniqueId = (email, id) => {
  firebase
    .firestore()
    .collection("uniqueIds")
    .doc(email)
    .set(id)
    .then(r => {})
}

export const setKeys = (uid, data) => {
  firebase
    .firestore()
    .collection("keys")
    .doc(uid)
    .set(data)
    .then(r => {})
}

export const getUniqueId = async email => {
  return firebase
    .firestore()
    .collection("uniqueIds")
    .doc(email)
    .get()
}

export const getKey = async key => {
  return firebase
    .firestore()
    .collection("keys")
    .doc(key)
    .get()
}

export const updateKey = async (key, data) => {
  return firebase
    .firestore()
    .collection("keys")
    .doc(key)
    .set(data)
}

export const resetPassword = async email => {
  return firebase.auth().sendPasswordResetEmail(email)
}
