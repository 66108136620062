import { StyleSheet } from "react-native-web"

export const globalStyle = StyleSheet.create({
  tabTitleText: {
    color: "#7D7D7D",
    fontWeight: "600",
    fontSize: 10,
  },
  selectedTabTitleText: {
    color: "#c37917",
    fontWeight: "600",
    fontSize: 10,
  },
})

export const colors = {
  navBarContent: "#ffffff",
  navBarBackground: "#ffffff",
  text: "#ffffff",
  activeColor: "#F4A30F",
  inactiveColor: "#7D7D7D",
  background: "#000000",
  startColor: "#07bc28",
  lapColor: "#ffffff",
  stopColor: "#AB3326",
}

export const androidSamsungColors = {
  navBarContent: "#ffffff",
  navBarBackground: "#ffffff",
  text: "#ffffff",
  activeColor: "#6364D7",
  inactiveColor: "#7D7D7D",
  background: "#F2F2F2",
  startColor: "#07bc28",
  lapColor: "#000000",
  stopColor: "#AB3326",
}
