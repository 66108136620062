import React from 'react';
import {
  Dimensions,
  Switch,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from 'react-native-web';
import { colors } from '../style/common';
import { IoIosClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { addPreset, editPreset, updatePreset } from '../redux/states/app';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { forceType, ruleTypes } from '../config/enum';
import ForceTypePicker from '../component/FoceTypePicker';
import TextField from '@material-ui/core/TextField';
import InputTags from '../component/inputTags';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

const types = [
  {
    code: 'MS',
    label: 'MS: Milliseconds',
    notes: 'You can create a force sequence by hitting return after each value',
  },
  {
    code: 'TO',
    label: 'TO: Total',
    notes: 'You can create a force sequence by hitting return after each value',
  },
  {
    code: 'FT',
    label: 'FT: Full Time',
    notes: '',
  },
];

const { width } = Dimensions.get('window');

export const OptionSwitch = ({ disabled, label, value, onChange }) => (
  <View
    style={{
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 8,
    }}
  >
    <Switch
      disabled={disabled}
      value={value}
      onValueChange={onChange}
      style={{ marginRight: 20 }}
      activeThumbColor={colors.activeColor}
      activeTrackColor={colors.activeColor}
    />
    <Text
      style={{
        fontSize: 16,
        color: 'white',
      }}
    >
      {label}
    </Text>
  </View>
);

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function AddPreset({ add, presetToEdit, update, edit }) {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  const [submitCount, setSubmitCount] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('initState');
  const [rulesTypes, setRulesTypes] = React.useState(Object.keys(ruleTypes));
  console.log('render AddPreset');
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    return () => {
      edit(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <View style={{ flex: 1 }}>
      <Formik
        initialValues={{
          type: presetToEdit ? presetToEdit.item.type : 'MS',
          name: presetToEdit ? presetToEdit.item.name : '',
          value: presetToEdit ? `${presetToEdit.item.value}`.split(',') : [],
          keepForce:
            presetToEdit && presetToEdit.item.keepForce
              ? presetToEdit.item.keepForce
              : false,
          tapOnLap:
            presetToEdit && presetToEdit.item.tapOnLap
              ? presetToEdit.item.tapOnLap
              : false,
          showOnTap:
            presetToEdit && presetToEdit.item.showOnTap
              ? presetToEdit.item.showOnTap
              : false,
          selected: presetToEdit ? presetToEdit.item.selected : false,
          forceRule:
            presetToEdit && presetToEdit.item.forceRule
              ? presetToEdit.item.forceRule
              : ruleTypes.SECONDS,
          forceValue:
            presetToEdit && presetToEdit.item.forceValue
              ? presetToEdit.item.forceValue
              : 3,
          forceType:
            presetToEdit && presetToEdit.item.forceType
              ? presetToEdit.item.forceType
              : forceType.STOPS,
        }}
        onSubmit={values => {
          if (presetToEdit) {
            update({
              item: values,
              index: presetToEdit.index,
              uid: user.uid,
              user,
            });
          } else {
            add({ ...values, uid: user.uid, user });
          }
          history.goBack();
        }}
        validationSchema={Yup.object({
          type: Yup.string().required('required'),
          name: Yup.string().required('Name required'),
          value: Yup.array()
            .min(
              1,
              'You have to set at least one value (press return to validate)',
            )
            .required('Value required'),
          forceValue: Yup.number()
            .moreThan(0, 'This value must be more than 0')
            .required('required'),
        })}
      >
        {formikProps => {
          console.log('render formik', formikProps.values);
          return (
            <ScrollView
              style={{ flex: 1 }}
              contentContainerStyle={{
                backgroundColor: colors.background,
                padding: 20,
                marginBottom: 48,
              }}
              showsVerticalScrollIndicator
            >
              <Snackbar
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
              <TouchableOpacity
                onPress={() => {
                  history.goBack();
                }}
              >
                <IoIosClose
                  color={'white'}
                  size={48}
                  style={{ marginBottom: 20 }}
                />
              </TouchableOpacity>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 16,
                }}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: colors.text,
                  }}
                >
                  New Preset
                </Text>
                <Button
                  onClick={() => {
                    formikProps.handleSubmit();
                    setSubmitCount(submitCount + 1);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  {presetToEdit ? 'Save' : 'Add'}
                </Button>
              </View>
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                inputProps={{ focused: true }}
                defaultValue={formikProps.values.name}
                onChange={formikProps.handleChange('name')}
                style={{ marginBottom: 8 }}
                autoFocus
              />

              {formikProps.errors.name && submitCount > 0 && (
                <Text style={{ marginVertical: 4, color: 'red' }}>
                  {formikProps.errors.name}
                </Text>
              )}
              <TextField
                style={{ marginBottom: 8 }}
                select
                value={
                  types.filter(item => item.code == formikProps.values.type)[0]
                    .label
                }
                onChange={e => {
                  const type = types.filter(
                    item => item.label == e.target.value,
                  )[0].code;
                  if (
                    type == 'MS' &&
                    formikProps.values.value &&
                    formikProps.values.value.filter(e => e.length > 2).length >
                      0
                  ) {
                    setOpen(true);
                    setErrorMessage(
                      "Values with more than 2 figures can't be set for millisecond",
                    );
                  } else {
                    if (type != 'TO') {
                      formikProps.setFieldValue('showOnTap', false);
                      formikProps.setFieldValue('keepForce', false);
                      formikProps.setFieldValue('tapOnLap', false);
                    }
                    formikProps.handleChange('type')(type);
                  }
                  if (type == 'FT') {
                    formikProps.setFieldValue('forceType', forceType.STOPS);
                  }
                }}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                variant="outlined"
              >
                {types.map(option => (
                  <option key={option.code} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <InputTags
                minLength={
                  formikProps.values.type === 'FT' ||
                  (formikProps.values.type === 'TO' &&
                    (formikProps.values.showOnTap ||
                      formikProps.values.keepForce ||
                      formikProps.values.tapOnLap))
                    ? 1
                    : undefined
                }
                maxValueLength={
                  formikProps.values.type === 'MS'
                    ? 2
                    : formikProps.values.type === 'FT'
                    ? 6
                    : undefined
                }
                maxValue={formikProps.values.type === 'TO' ? 158 : undefined}
                defaultValue={formikProps.values.value}
                onChange={v => {
                  // console.log("------------------------------------------------");
                  formikProps.setFieldValue('value', v);
                }}
              />

              {formikProps.errors.value && submitCount > 0 && (
                <Text style={{ marginVertical: 4, color: 'red' }}>
                  {formikProps.errors.value}
                </Text>
              )}
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Text style={{ color: colors.text, marginRight: 20 }}>
                    Force after:
                  </Text>
                  <View style={{ flex: 1 }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      placeholder={'Number'}
                      fullWidth
                      value={formikProps.values.forceValue}
                      onChange={formikProps.handleChange('forceValue')}
                    />
                  </View>

                  <View style={{ marginLeft: 8, flex: 1 }}>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                      value={formikProps.values.forceRule}
                      onChange={e => {
                        formikProps.handleChange('forceRule')(
                          e.target.value.toUpperCase(),
                        );
                      }}
                    >
                      {rulesTypes.map(option => (
                        <option
                          key={option.toLowerCase()}
                          value={option.toUpperCase()}
                        >
                          {option.toLowerCase()}
                        </option>
                      ))}
                    </TextField>
                  </View>
                </View>
              </View>
              {formikProps.errors.forceValue && submitCount > 0 && (
                <Text style={{ marginVertical: 4, color: 'red' }}>
                  {formikProps.errors.forceValue}
                </Text>
              )}
              <ForceTypePicker
                selectedValue={formikProps.values.forceType}
                onSelect={value => {
                  formikProps.handleChange('forceType')(value);
                }}
                isFullTime={formikProps.values.type == 'FT'}
                disabledItems={
                  formikProps.values.tapOnLap
                    ? [forceType.STOPS, forceType.EITHER]
                    : formikProps.values.showOnTap
                    ? [forceType.STOPS]
                    : []
                }
                advancedOptions={formikProps.values.tapOnLap}
              />
              <Text style={{ color: colors.text }}>
                {
                  types.filter(item => item.code === formikProps.values.type)[0]
                    .notes
                }
              </Text>
              {formikProps.values.type === 'TO' && (
                <View style={{ marginTop: 8 }}>
                  <View
                    style={{
                      backgroundColor: colors.text,
                      height: 2,
                      marginVertical: 16,
                    }}
                  />
                  <Text style={{ color: colors.text }}>Advanced options</Text>
                  <OptionSwitch
                    disabled={
                      `${formikProps.values.value}`.split(',').length > 1
                    }
                    value={formikProps.values.keepForce}
                    onChange={value => {
                      if (!value) {
                        formikProps.setFieldValue('showOnTap', false);
                      }
                      formikProps.setFieldValue('keepForce', value);
                      formikProps.setFieldValue('tapOnLap', false);
                    }}
                    label={'Keep Force'}
                  />
                  {formikProps.values.keepForce && (
                    <OptionSwitch
                      disabled={
                        `${formikProps.values.value}`.split(',').length > 1
                      }
                      value={formikProps.values.showOnTap}
                      onChange={value => {
                        formikProps.setFieldValue('showOnTap', value);
                        formikProps.handleChange('forceType')(forceType.LAPS);
                      }}
                      label={'Change others on lap tap'}
                    />
                  )}
                  <OptionSwitch
                    disabled={
                      `${formikProps.values.value}`.split(',').length > 1
                    }
                    value={formikProps.values.tapOnLap}
                    onChange={value => {
                      formikProps.setFieldValue('tapOnLap', value);
                      formikProps.setFieldValue('keepForce', false);
                      formikProps.setFieldValue('showOnTap', false);
                      formikProps.handleChange('forceType')(forceType.LAPS);
                    }}
                    label={'Tap on Lap to Force'}
                  />
                </View>
              )}
              <View
                style={{
                  height: '100',
                  backgroundColor: 'red',
                }}
              />
            </ScrollView>
          );
        }}
      </Formik>
    </View>
  );
}

export default connect(
  state => ({
    presetToEdit: state.app.presetToEdit,
    user: state.auth.user,
  }),
  dispatch => ({
    add: payload => dispatch(addPreset(payload)),
    update: payload => dispatch(updatePreset(payload)),
    edit: payload => dispatch(editPreset(payload)),
  }),
)(AddPreset);
