import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { getKey, updateKey } from '../services/firebase';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateKeys } from '../redux/states/app';
import { generateHash } from '../helpers/validation';

const KeyCheck = ({ updateKeys }) => {
  const [key, setKey] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
  };
  const validateKey = () => {
    if (key !== '') {
      console.log(key);
      getKey(key).then(r => {
        console.log(r);
        const data = r.data();
        if (data) {
          console.log(data);
          if (data.isActivated) {
            setOpen(true);
            setErrorMessage('Your key is already installed in another device ');
          } else if (data.activationNumber >= data.maxActivationNumber) {
            setOpen(true);
            setErrorMessage(
              'Your have reached the maximum activation for this key',
            );
          } else {
            updateKey(r.ref.id, {
              ...data,
              activationNumber: data.activationNumber + 1,
              isActivated: true,
            }).then(res => {
              console.log(res);
              const keyObject = {
                dateLogin: new Date(),
                activationKey: r.ref.id,
              };
              const token = generateHash(keyObject);
              updateKeys({
                ...keyObject,
                token,
                remainingTimes:
                  data.maxActivationNumber - data.activationNumber - 1,
              });
              history.replace('/');
            });
          }
        } else {
          setOpen(true);
          setErrorMessage('Your entered invalid key ');
        }
      });
    } else {
      setOpen(true);
      setErrorMessage('Please enter a valid key');
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <form style={{ width: '80%' }} noValidate autoComplete="off">
        <TextField
          style={{ marginBottom: 16 }}
          fullWidth
          label="Key"
          variant="outlined"
          placeholder="Key"
          onChange={e => {
            setKey(e.target.value);
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={validateKey}
        >
          <h5>Validate</h5>
        </Button>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default connect(
  state => ({}),
  dispatch => ({ updateKeys: payload => dispatch(updateKeys(payload)) }),
)(KeyCheck);
