import React from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import { colors } from '../style/common';

const Alarm = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>Alarm Page</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: colors.background,
  },
  text: { color: 'white' },
});
export default Alarm;
