import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native-web';
import { useHistory } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import { colors } from '../style/common';
import InputTags from '../component/inputTags';
import Button from '@material-ui/core/Button';
import { advancedTypes } from '../config/enum';
import { connect } from 'react-redux';
import { updateCurrentPreset } from '../redux/states/app';
import RadioButton from '../component/RadioButton';
import StackMode from '../component/StackMode';

const SecondInput = ({ updateCurrentPreset, settings }) => {
  let history = useHistory();
  const types = Object.keys(advancedTypes).map(e => ({
    value: e,
    label: e.toLowerCase(),
  }));
  const [type, setType] = React.useState(types[1].value);
  const [value, setValue] = React.useState([]);

  const onSave = type => {
    if (value.length > 0) {
      updateCurrentPreset({
        type,
        selected: true,
        value,
        ...{
          ...settings,
          checking: [],
        },
      });
      history.goBack();
    } else {
      alert('You have to set at least one value press comma to validate');
    }
  };
  const onSaveStack = (type, value) => {
    if (value.length > 0) {
      updateCurrentPreset({
        type,
        selected: true,
        value,
        ...{
          ...settings,
          checking: [],
        },
      });
      history.goBack();
    } else {
      alert('You have to set at least one value press comma to validate');
    }
  };
  return (
    <ScrollView
      style={{ backgroundColor: colors.background, padding: 20, flex: 1 }}
    >
      <TouchableOpacity
        onPress={() => {
          history.goBack();
        }}
      >
        <IoIosClose color={'white'} size={48} style={{ marginBottom: 20 }} />
      </TouchableOpacity>
      <RadioButton
        defaultValue={advancedTypes.SPREAD}
        options={Object.keys(advancedTypes).map(e => ({
          value: e,
          label: e.toLowerCase(),
        }))}
        onChange={value => {
          setType(value);
          setValue([]);
        }}
      />
      <InputTags
        onChange={v => {
          console.log(v);
          setValue(v);
        }}
        defaultValue={value}
        focused={false}
        mode={type}
        maxValue={type == advancedTypes.ADDITION ? 900 : undefined}
      />
      <View style={{ flexDirection: 'row' }}>
        <Button
          style={{ flex: 1 }}
          variant="contained"
          color="primary"
          onClick={() => {
            onSave('MS');
          }}
        >
          Save as MS
        </Button>
        <div style={{ padding: 4 }} />
        <Button
          style={{ flex: 1 }}
          variant="contained"
          color="primary"
          onClick={() => {
            onSave('TO');
          }}
        >
          Save as TOTAL
        </Button>
      </View>
      {/* <StackMode onSave={onSaveStack} /> */}
    </ScrollView>
  );
};

export default connect(
  state => ({
    settings: state.app.settings || {},
  }),
  dispatch => ({
    updateCurrentPreset: payload => dispatch(updateCurrentPreset(payload)),
  }),
)(SecondInput);
