export const OPEN_APP = "AuthState/LOGIN"
export const CLOSE_APP = "AuthState/LOGOUT"

export const initialState = {
  user: {},
}

export const login = payload => {
  return {
    type: OPEN_APP,
    payload,
  }
}

export const logout = () => {
  return {
    type: CLOSE_APP,
  }
}

export const selectUid = state => {
  return state.user.uid
}

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_APP:
      return { ...state, user: action.payload }
    case CLOSE_APP:
      return { ...state, user: null }
    default:
      return state
  }
}
