import React, { useState } from "react"
import { Dimensions, Text, TouchableOpacity, View } from "react-native-web"
import { IoIosClose } from "react-icons/io"
import { connect } from "react-redux"
import { updateCurrentPresetInDB } from "../redux/states/app"
import { useHistory } from "react-router-dom"
import InputTags from "../component/inputTags"
import MuiAlert from "@material-ui/lab/Alert"
import Snackbar from "@material-ui/core/Snackbar"

const { height } = Dimensions.get("window")

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
const InputPage = ({ updateCurrentPresetInDB, settings }) => {
  let history = useHistory()
  const [value, setValue] = useState("")
  const [open, setOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("initState")
  const [defaultValue, setDefaultValue] = useState([])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <View style={{ padding: 20 }}>
      <TouchableOpacity
        onPress={() => {
          history.goBack()
        }}
      >
        <IoIosClose color={"white"} size={48} style={{ marginBottom: 20 }} />
      </TouchableOpacity>
      <View style={{ height: height / 2.2 }}>
        <View style={{ position: "absolute", bottom: 0, width: "100%" }}>
          <InputTags
            onChange={v => {
              setValue(v)
            }}
            focused
            defaultValue={defaultValue}
          />
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              marginBottom: 16,
            }}
          >
            <TouchableOpacity
              disabled={value.length < 1}
              style={{
                backgroundColor: "#0176FF",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: 16,
              }}
              onPress={() => {
                if (value.filter(v => v.length > 2).length > 0) {
                  setOpen(true)
                  setErrorMessage(
                    "Values with more than 2 figures can't be set for millisecond",
                  )
                } else {
                  if (value.length > 0) {
                    updateCurrentPresetInDB({
                      data: {
                        type: "MS",
                        selected: true,
                        value,
                        ...{
                          ...settings,
                          checking: [],
                        },
                      },
                    })
                    history.goBack()
                  } else {
                    alert(
                      "You have to set at least one value press comma to validate",
                    )
                  }
                }
              }}
            >
              <Text style={{ color: "white", fontSize: 20 }}>MS</Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={value.length < 1}
              style={{
                backgroundColor: "#54C242",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: 16,
              }}
              onPress={() => {
                if (value.length > 0) {
                  updateCurrentPresetInDB({
                    data: {
                      type: "TO",
                      selected: true,
                      value,
                      ...{
                        ...settings,
                        checking: [],
                        totalSettings: [],
                        forceType: settings.totalForceType,
                        keepForce:
                          value.length > 1
                            ? false
                            : settings.totalSettings[0].checked,
                        tapOnLap:
                          value.length > 1
                            ? false
                            : settings.totalSettings[2].checked,
                        showOnTap:
                          value.length > 1
                            ? false
                            : settings.totalSettings[1].checked,
                      },
                    },
                  })
                  history.goBack()
                } else {
                  alert(
                    "You have to set at least one value press comma to validate",
                  )
                }
              }}
            >
              <Text style={{ color: "white", fontSize: 20 }}>Total</Text>
            </TouchableOpacity>
            <TouchableOpacity
              // disabled={value.length != 1}
              style={{
                backgroundColor: "#F28501",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: 16,
              }}
              onPress={() => {
                if (value.length > 0) {
                  updateCurrentPresetInDB({
                    data: {
                      type: "FT",
                      selected: true,
                      value,
                      ...{ ...settings, checking: [] },
                    },
                  })
                  history.goBack()
                } else {
                  alert(
                    "You have to set at least one value press comma to validate",
                  )
                }
              }}
            >
              <Text style={{ color: "white", fontSize: 20 }}>FT</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <Snackbar
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </View>
  )
}

export default connect(
  state => {
    return {
      preset: state.app.currentPreset,
      presets: state.app.presets,
      settings: state.app.settings || {},
    }
  },
  dispatch => ({
    // updateCurrentPreset: payload => dispatch(updateCurrentPreset(payload)),
    updateCurrentPresetInDB: payload =>
      dispatch(updateCurrentPresetInDB(payload)),
  }),
)(InputPage)
