/**
 * React Chronometer
 */
import React from "react"
import { FlatList } from "react-native-web"
import Text from "react-native-web/dist/exports/Text"
import View from "react-native-web/dist/exports/View"
import ActionButton from "./ActionButton"
import { connect } from "react-redux"
import {
  bulkUpdateLogs,
  resetPreset,
  toggleVisualHint,
  toggleResetVisualHint,
  updateCurrentPreset,
  updateLogs,
} from "../redux/states/app"
import LapTile from "./LapTile"
import { forceType, ruleTypes } from "../config/enum"
import SwipeView from "./SwipeView"
import PresetTile from "./PresetTile"
import { withNamespaces } from "react-i18next"
import { withTheme } from "./../style/themes"
import DoubleTapLink from "./DoubleTapLink"
import { MdMoreVert } from "react-icons/md"
import { withRouter } from "react-router-dom"
import { getSettingsRef, removeSetting } from "../services/firebase"

const initState = {
  startTS: null,
  startTSLap: null,
  diff: null,
  suspended: +new Date(0),
  lapSuspended: +new Date(0),
  interval: null,
  tries: 0,
  laps: [],
  initLaps: ["", "", "", "", "", "", "", ""],
  context: null,
  forceCount: -1,
  ready: true,
  readyLap: true,
  anticipated: false,
  changeCount: 0,
  trigger: 0,
  forceTimes: 0,
  forcedStop: null,
  anticipationValue: 0,
  started: false,
  lastLap: null,
  recurrenceValue: null,
  isRecurrenceForced: false,
  isNotAdded: true,
}

class Chronometer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...initState,
    }
  }

  componentWillUnmount() {
    this.props.toggleVisualHint({ showVisualHint: false })
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.preset != this.props.preset && !nextProps.preset) {
      this.setState(initState)
    } else {
      if (
        nextState.trigger != this.state.trigger ||
        nextState.anticipationValue != this.state.anticipationValue
      ) {
        if (
          this.props.preset &&
          nextState.trigger + nextState.anticipationValue ==
            this.props.preset.forceValue
        ) {
          this.props.toggleVisualHint({ showVisualHint: true })
        } else {
          this.props.toggleVisualHint({ showVisualHint: false })
        }
      }
    }
  }

  componentDidMount() {
    const email = this.props.user.email
    // Get a reference to the specific document in the 'stops' collection
    const documentRef = getSettingsRef(email)

    // Set up the real-time listener
    this.unsubscribe = documentRef?.onSnapshot(docSnapshot => {
      if (docSnapshot?.exists) {
        // Get the updated document from the snapshot
        const updatedDoc = docSnapshot.data()
        console.log("Update Document : ", updatedDoc)
        this.props.updateCurrentPreset(updatedDoc)
        removeSetting(email)
      }
    })
  }

  componentDidMount() {
    this.props.toggleResetVisualHint({ showResetVisualHint: false })
  }

  componentWillUnmount() {
    // Clean up the listener when the component unmounts
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  incrementTrigger = () => {
    this.setState({
      trigger: this.state.trigger + 1,
    })
  }
  incrementTimes = () => {
    this.setState({
      forceTimes: this.state.forceTimes + 1,
    })
  }

  renderTotal = (forceValue, seconds) => {
    let forceSecond
    let forceHundredths
    if (forceValue <= seconds) {
      forceSecond = forceValue
    } else {
      forceSecond = seconds
    }
    forceHundredths = forceValue - forceSecond
    return { forceSecond, forceHundredths }
  }
  renderFullTime = value => {
    let newH = 0
    let newS = 0
    let newM = 0
    let ft = `${value}`
    if (ft.length > 6) {
      ft = ft.substr(ft.length - 6, ft.length)
    }
    if (ft.length > 4) {
      newM = parseInt(ft.substr(0, ft.length - 4))
      ft = ft.substr(ft.length - 4, ft.length)
    }
    if (ft.length > 2) {
      newS = parseInt(ft.substr(0, ft.length - 2))
      ft = ft.substr(ft.length - 2, ft.length)
    }
    if (ft.length > 0) {
      newH = parseInt(ft)
    }
    return { newH, newM, newS }
  }

  start = () => {
    if (this.state.startTS) {
      return
    }

    if (this.props.showResetVisualHint)
      this.props.toggleResetVisualHint({ showResetVisualHint: false })
    if (this.state.forceTimes > 0) {
      this.checkForce()
    } else {
      const newDate = new Date()
      this.setState({
        tries: this.state.tries + 1,
        startTS: +newDate - this.state.suspended,
        startTSLap: +newDate - this.state.lapSuspended,
        interval: requestAnimationFrame(this.tick),
        suspended: +new Date(0),
        forcedStop: null,
        started: true,
      })
    }
  }

  addZeros = value => {
    let newValue = value
    for (let i = 0; i < 6 - value.length; i++) {
      newValue = `0${newValue}`
    }
    return newValue
  }

  checkForce = () => {
    const valueToCheck = this.renderStopAgain()
    const values = valueToCheck
      .replace(this.props.settings.useComma ? "," : ".", ":")
      .split(":")
    const value = this.addZero(
      this.props.preset.value[this.state.forceTimes - 1],
    )
    const newDate = new Date()
    let startTS = +newDate - this.state.suspended
    let startTSLap = +newDate - this.state.lapSuspended
    let flag = true
    switch (this.props.preset.type) {
      case "MS":
        flag = value == values[2]
        break
      case "FT":
        if (value) {
          flag = this.addZeros(value) == values.reduce((p, c) => `${p}${c}`)

          if (flag) {
            const date = new Date(+this.state.diff)
            date.setUTCMinutes(values[0], values[1], values[2])

            startTS =
              +newDate - this.state.suspended - (+date - this.state.diff)
          }
        }
        break
      case "TO":
        if (values[1] > value) {
          flag = value == values[2]
        } else {
          const newValue = values.map(i => parseInt(i)).reduce((r, c) => r + c)
          console.log(newValue, value)
          flag = newValue == value
        }
        break
    }
    this.setState({
      forceTimes: !flag ? this.state.forceTimes - 1 : this.state.forceTimes,
      tries: this.state.tries + 1,
      startTS,
      startTSLap,
      interval: requestAnimationFrame(this.tick),
      suspended: +new Date(0),
      forcedStop: null,
      started: true,
    })
  }

  stopAgain = () => {
    const preset = this.props.preset
    if (preset?.isRecurrence && !this.state.isRecurrenceForced) {
      this.props.updateCurrentPreset({
        ...preset,
        value: [100 - Math.round(this.state.diff.getMilliseconds() / 10)],
      })
      this.setState({
        isRecurrenceForced: !this.state.isRecurrenceForced,
      })
    }
    if (this.state.isRecurrenceForced) {
      this.setState({
        isRecurrenceForced: !this.state.isRecurrenceForced,
      })
    }
    //increment trigger
    if (preset && preset.forceRule == ruleTypes.STOPS) {
      this.incrementTrigger()
    }

    //increment force times
    if (
      this.props.preset &&
      [forceType.EITHER, forceType.STOPS].includes(
        this.props.preset.forceType,
      ) &&
      this.state.trigger + this.state.anticipationValue >=
        this.props.preset.forceValue
    ) {
      this.incrementTimes()
    }

    let forceFlag = false

    let value = 0
    if (preset && this.state.forceTimes <= preset.value.length) {
      value = preset.value[this.state.forceTimes]
    }
    if (preset?.keepForce || this.state.isRecurrenceForced) {
      value = preset.value[0]
    }
    //check if the force is applied
    if (
      preset &&
      ((preset.isRecurrence && this.state.isRecurrenceForced) ||
        !preset.isRecurrence) &&
      [forceType.EITHER, forceType.STOPS].includes(preset.forceType) && //check force type of STOPS
      (this.state.forceTimes < preset.value.length ||
        preset.keepForce ||
        this.state.isRecurrenceForced) && //check if the force is completed
      (this.state.isRecurrenceForced ||
        this.state.trigger + this.state.anticipationValue >= preset.forceValue) //check if the force is triggered
    ) {
      forceFlag = true
    }
    const stop = forceFlag
      ? {
          value: new Date(+new Date() - this.state.startTS),
          forcedValue: value,
        }
      : null

    const lapsValues =
      this.state.laps.length > 0
        ? this.state.laps
            .map(e => e.value)
            .reduce((total, current) => +total + +current)
        : 0

    this.setState(
      {
        startTS: null,
        forcedStop: stop,
        suspended: +this.state.diff,
        lapSuspended: +this.state.diff - lapsValues,
      },
      () => {
        console.log("state:", this.state)
      },
    )
    if (stop) {
      this.incrementTrigger()
    }
    this.props.updateLogs({
      ms: new Date(this.state.diff).getMilliseconds(),
      sc: new Date(this.state.diff).getSeconds(),
      email: this.props.user.email,
    })
    cancelAnimationFrame(this.state.interval)
  }

  lapAgain = () => {
    const newDate = new Date()

    if (!this.state.startTS) {
      return
    }
    const preset = this.props.preset
    if (preset && preset.forceRule == ruleTypes.LAPS) {
      this.incrementTrigger()
    }
    let forceFlag = false

    let value = 0
    if (preset && this.state.forceTimes <= preset.value.length) {
      value = preset.value[this.state.forceTimes]
    }
    if (preset && (preset.keepForce || preset.tapOnLap)) {
      value = preset.value[0]
    }
    //check if the force is applied
    if (
      preset &&
      [forceType.EITHER, forceType.LAPS].includes(preset.forceType) && //check force type of STOPS
      (this.state.forceTimes < preset.value.length ||
        preset.keepForce ||
        preset.tapOnLap) && //check if the force is completed
      (this.state.trigger + this.state.anticipationValue >= preset.forceValue ||
        (preset.forceRule == ruleTypes.LAPS && preset.tapOnLap)) //check if the force is triggered
    ) {
      forceFlag = true
      this.incrementTrigger()
    }

    const lap = {
      value: new Date(
        new Date() - this.state.startTSLap - this.state.suspended,
      ),
      selected: forceFlag && preset && preset.showOnTap,
      forced: forceFlag,
      forcedValue: value,
      timeOnChrono: new Date(+new Date() - this.state.startTS),
    }
    this.setState({
      laps: [lap, ...this.state.laps],
      startTSLap: newDate,
      forceCount: lap.forced
        ? this.state.forceCount + 1
        : this.state.forceCount,
      lastLap: new Date(+new Date() - this.state.startTS),
    })
    //increment force times
    if (
      preset &&
      [forceType.LAPS, forceType.EITHER].includes(
        this.props.preset.forceType,
      ) &&
      this.state.trigger + this.state.anticipationValue >=
        this.props.preset.forceValue
    ) {
      this.incrementTimes()
    }
  }

  lap = () => {
    if (!this.state.startTS) {
      return
    }
    this.lapAgain()
    let values = []
    if (this.props.preset) {
      values = `${this.props.preset.value}`.split(",")
    }
    let lap = {}
    if (this.props.preset && this.props.preset.tapOnLap) {
      lap = {
        value: new Date(+new Date() - this.state.startTSLap),
        selected: false,
        forced: false,
      }
      console.log("lap case1", lap)
    } else if (this.props.preset && this.props.preset.showOnTap) {
      lap = {
        value: new Date(+new Date() - this.state.startTSLap),
        selected: true,
        forced: true,
      }
      console.log("lap case2", lap)
    } else if (
      this.props.preset &&
      this.props.preset.forceRule === ruleTypes.LAPS
    ) {
      lap = {
        value: new Date(+new Date() - this.state.startTSLap),
        selected:
          this.state.laps.length - this.props.preset.forceValue <
            values.length &&
          this.state.laps.length >= this.props.preset.forceValue,
        forced:
          this.state.forceCount < values.length &&
          this.state.laps.length >= this.props.preset.forceValue,
      }
      console.log("lap case3", lap)
    } else {
      lap = {
        value: new Date(+new Date() - this.state.startTSLap),
        selected: this.state.laps.length < values.length,
        forced: this.state.laps.length < values.length,
      }
      console.log("lap case4", lap)
    }
    this.setState({
      startTSLap: new Date(),
      // laps: [lap, ...this.state.laps],
      forceCount: lap.forced
        ? this.state.forceCount + 1
        : this.state.forceCount,
    })

    // for visual hint
    if (this.props.preset && this.props.preset.forceRule === ruleTypes.LAPS) {
      console.log(
        "laps case",
        this.state.laps.length,
        this.props.preset.forceValue,
      )
      if (this.state.laps.length + 1 == this.props.preset.forceValue) {
        this.props.toggleVisualHint({ showVisualHint: true })
      } else {
        this.props.toggleVisualHint({ showVisualHint: false })
      }
    }
  }

  reset = () => {
    cancelAnimationFrame(this.state.interval)
    if (
      this.props.preset &&
      this.props.preset.value.length <= this.state.forceTimes
    ) {
      console.log("case 1")
      this.setState({ ...initState })
    } else if (this.state.forceTimes > 0) {
      console.log("case 2")
      this.setState({
        ...initState,
        forceTimes: this.state.forceTimes + 1,
        trigger: this.state.trigger,
      })
    } else {
      console.log("case 3")
      this.setState({
        ...initState,
        trigger: this.state.trigger,
        forceTimes: this.state.forceTimes,
      })
      if (
        this.props.preset &&
        this.props.preset.forceRule == ruleTypes.RESETS
      ) {
        console.log("incremented")
        this.incrementTrigger()
      }
    }
    if (
      this.props.preset &&
      this.state.forceTimes >= this.props.preset.value.length
    ) {
      this.props.updateCurrentPreset(
        this.props.presets.filter(i => i.selected)[0] || null,
      )
    }
  }

  resetForce = () => {
    if (this.props.preset && this.props.settings.checking[1]?.checked) {
      console.log("------- resetForce ------ ")
      this.props.toggleResetVisualHint({ showResetVisualHint: true })
      this.setState({
        trigger: 0,
        tries: 0,
        forceTimes: 0,
      })
    } else this.props.toggleResetVisualHint({ showResetVisualHint: false })
  }

  anticipateForce = () => {
    if (this.props.preset && this.props.settings.checking[0]?.checked) {
      console.log("anticipateForce")
      this.setState({
        anticipationValue: this.props.preset.forceValue - this.state.trigger,
      })
    }
  }

  tick = () => {
    const newDiff = new Date(+new Date() - this.state.startTS)

    this.setState({
      diff: newDiff,
      interval: requestAnimationFrame(this.tick),
      trigger:
        this.props.preset &&
        this.props.preset.forceRule == ruleTypes.SECONDS &&
        newDiff.getSeconds() > this.state.trigger &&
        this.state.trigger < this.props.preset.forceValue
          ? this.state.trigger + 1
          : this.state.trigger,
    })
  }

  addZero = n => {
    const parsedN = parseInt(`${n}`)
    return parsedN < 10 ? "0" + parsedN : parsedN
  }
  renderLapsAgain = lapIndex => {
    const lap = this.state.laps[lapIndex]
    const preset = this.props.preset
    const diff = lap.value
    let forceFlag = false
    if (lap.forced) {
      if (preset.tapOnLap || preset.showOnTap) {
        if (lap.selected) {
          forceFlag = true
        } else {
          forceFlag = false
        }
      } else {
        forceFlag = true
      }
    }
    const value = lap.forcedValue

    if (forceFlag) {
      return this.formatResult(
        diff,
        value,
        preset.type,
        forceFlag,
        !lap.forced && this.state.startTS == null,
      )
    } else {
      return this.formatResult(diff)
    }
  }
  select = index => {
    let newData = [...this.state.laps]
    if (
      this.props.preset.tapOnLap
      // && this.state.changeCount < 1
    ) {
      newData = this.state.laps.map((element, i) => ({
        ...element,
        selected: index == i,
        forced: true,
      }))
      this.setState({
        changeCount: this.state.changeCount + 1,
      })
    } else if (
      this.props.preset.showOnTap
      // && this.state.changeCount < 1
    ) {
      newData = this.state.laps.map((element, i) => ({
        ...element,
        selected: index == i,
        // forced: true,
      }))
      this.setState({
        changeCount: this.state.changeCount + 1,
      })
    }
    this.setState({ laps: newData })
  }
  renderLaps = (lapIndex = 0) => {
    const lap = this.state.laps[lapIndex]
    const preset = this.props.preset
    const diff = lap.value

    if (preset) {
      const values = `${preset.value}`.split(",")

      let value = values[0]
      if (preset && preset.forceRule === ruleTypes.LAPS) {
        value =
          values[
            (this.state.laps.length - preset.forceValue - 1 - lapIndex) %
              values.length
          ]
      } else {
        value = values[(this.state.laps.length - 1 - lapIndex) % values.length]
      }
      if (
        preset &&
        preset.forceType != forceType.STOPS &&
        preset.type != "FT" &&
        lap.forced
      ) {
        return this.formatResult(diff, value, preset.type, true, false)
      } else {
        return this.formatResult(diff, value, preset.type, false, true)
      }
    } else {
      return this.formatResult(diff)
    }
  }
  renderStopAgain = () => {
    const stop = this.state.forcedStop
    const diff = stop ? stop.value : this.state.diff
    const preset = this.props.preset
    const value = stop ? stop.forcedValue : 0

    let result
    if (stop && preset) {
      result = this.formatResult(diff, value, preset.type, true, false)
    } else {
      result = this.formatResult(diff)
    }
    // this.props.updateLogs(result);
    return result
  }
  formatResult = (diff, value, type, forced = false, round, forLog) => {
    // console.log(
    //   "formatResultNewDiff",
    //   diff ? new Date(diff.toLocaleString("en-US", { timeZone: "UTC" })) : null,
    // )
    const newDiff = diff
      ? new Date(diff.toLocaleString("en-US", { timeZone: "UTC" }))
      : null
    const seconds = newDiff ? newDiff.getSeconds() : 0
    let hundredths = newDiff ? Math.round(diff.getMilliseconds() / 10) : 0
    const minutes = newDiff ? newDiff.getMinutes() : 0

    if (hundredths === 100) {
      hundredths = 0
    }
    let newM = minutes
    let newS = seconds
    let newH = hundredths
    if (forced) {
      if (type == "MS") {
        newH = value
      } else if (type == "FT") {
        const fullTime = this.renderFullTime(value)
        newM = fullTime.newM > 59 ? minutes : fullTime.newM
        newS = fullTime.newS > 59 ? seconds : fullTime.newS
        newH = fullTime.newH > 99 ? hundredths : fullTime.newH
      } else if (type == "TO") {
        if (newS >= value) {
          newH = value < 100 ? value : newH
        } else {
          const time = this.renderTotal(value, seconds)
          newS = time.forceSecond
          newH = time.forceHundredths > 99 ? hundredths : time.forceHundredths
        }
      }
    } else if (round) {
      if (
        (type === "MS" && newH == value) ||
        (type === "TO" && newS + newH == value)
      ) {
        if (value < 97) {
          newH = newH + 3
        } else {
          newH = newH - 3
        }
      }
    }

    return forLog
      ? { s: newS, m: newH }
      : `${this.addZero(newM)}:${this.addZero(newS)}${
          this.props.settings.useComma ? "," : "."
        }${this.addZero(newH)}`
  }

  render() {
    const { t, theme, width, height } = this.props

    const times = this.renderStopAgain()

    const lapTimes =
      this.state.laps.length > 0
        ? this.formatResult(new Date(+this.state.diff - this.state.lastLap))
        : ""

    let maxLap = null
    let minLap = null
    if (this.state.laps.length > 1) {
      minLap = this.state.laps.reduce((a, b) => {
        return a.value < b.value ? a : b
      })
      maxLap = this.state.laps.reduce((a, b) => {
        return a.value > b.value ? a : b
      })
    }

    return (
      <View
        style={{
          ...theme?.chrono?.container,
          width,
          height: height - theme?.chrono.container.height,
        }}
      >
        {this.props.themeKey != "iOS13" ? (
          <View
            style={{
              position: "absolute",
              zIndex: 90,
              right: 0,
              top: 0,
            }}
          >
            <DoubleTapLink
              style={{
                padding: 16,
                zIndex: 90,
              }}
              onDoubleClick={() => {
                this.props.history.push("/settings")
              }}
            >
              <View
                style={{
                  height: 24,
                  width: 24,
                  overflow: "hidden",
                }}
              >
                <MdMoreVert
                  size={24}
                  color={theme?.colors.text}
                  style={{
                    position: "absolute",
                    top: this.props.showVisualHint ? 8 : 0,
                    left: 0,
                  }}
                />
              </View>
            </DoubleTapLink>
          </View>
        ) : null}
        <View
          style={
            this.state.laps.length > 0
              ? theme?.chrono.textContainerWithLaps
              : theme?.chrono.textContainer
          }
        >
          <DoubleTapLink
            onDoubleClick={() => {
              this.props.history.push("/logs")
            }}
            style={theme?.chrono.textWrapper}
          >
            <View style={theme?.chrono.big}>
              {times.split("").map(e => (
                <Text
                  style={[
                    theme?.chrono.text,
                    [":", ",", "."].includes(e)
                      ? { width: undefined, backgroundColor: undefined }
                      : {},
                  ]}
                  numberOfLines={1}
                >
                  {e}
                </Text>
              ))}
            </View>
          </DoubleTapLink>
          {this.props.themeKey == "iOS13" ||
          this.state.laps.length == 0 ? null : (
            <View style={theme?.chrono.textWrapperLap}>
              <View style={theme?.chrono.bigLap}>
                <Text
                  style={{
                    ...theme?.chrono.textLap,
                  }}
                  numberOfLines={1}
                >
                  {lapTimes}
                </Text>
              </View>
            </View>
          )}
        </View>
        <View
          style={
            this.state.laps.length > 0
              ? theme?.chrono.layoutWithLaps
              : theme?.chrono.layout
          }
        >
          <View style={theme?.chrono.actionButtons}>
            {this.state.started || this.props.themeKey === "iOS13" ? (
              this.state.tries > 0 && !this.state.startTS ? (
                <ActionButton
                  label={t(`${this.props.themeKey === "iOS13" ? "" : ""}Reset`)}
                  color={theme?.colors.lapColor}
                  onPress={this.reset}
                  onLongPress={this.resetForce}
                />
              ) : (
                <ActionButton
                  label={t(`${this.props.themeKey === "iOS13" ? "" : ""}Lap`)}
                  color={theme?.colors.lapColor}
                  onPress={this.lapAgain}
                />
              )
            ) : null}
            {this.props.themeKey === "iOS13" ? (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View
                  style={{
                    height: 7,
                    width: 7,
                    borderRadius: 3.5,
                    backgroundColor: "#fff",
                    marginHorizontal: 5,
                  }}
                />
                <View
                  style={{
                    height: 6,
                    width: 6,
                    borderRadius: 3,
                    backgroundColor: "rgba(255,255,255,0.20)",
                    marginHorizontal: 5,
                  }}
                />
              </View>
            ) : null}
            <ActionButton
              onPress={this.state.startTS ? this.stopAgain : this.start}
              onLongPress={this.anticipateForce}
              label={
                this.state.startTS
                  ? t(`${this.props.themeKey === "iOS13" ? "" : ""}Stop`)
                  : t(`${this.props.themeKey === "iOS13" ? "" : ""}Start`)
              }
              color={
                this.state.startTS
                  ? theme?.colors.stopColor
                  : theme?.colors.startColor
              }
            />
          </View>
          <View
            style={
              this.state.laps.length > 0
                ? theme?.chrono.lapsContainerNoEmpty
                : theme?.chrono.lapsContainer
            }
          >
            {this.state.laps.length > 0 ? (
              <FlatList
                data={
                  this.state.laps.length > 0
                    ? this.state.laps
                    : this.state.initLaps
                }
                style={{ flex: 1 }}
                contentContainerStyle={theme?.chrono.lapList}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => {
                  return (
                    <LapTile
                      item={item === "" ? "" : this.renderLapsAgain(index)}
                      onTime={
                        item === "" ? "" : this.formatResult(item.timeOnChrono)
                      }
                      index={index}
                      select={() => this.select(index)}
                      lapsLength={this.props?.preset?.value?.length}
                      length={this.state?.laps?.length}
                      isMax={JSON.stringify(item) === JSON.stringify(maxLap)}
                      isMin={JSON.stringify(item) === JSON.stringify(minLap)}
                    />
                  )
                }}
              />
            ) : (
              <React.Fragment>
                <View style={theme?.chrono.lapsContainer}>
                  <View>
                    {this.state?.initLaps?.map((item, index) => (
                      <LapTile key={`emptyTile:${index}`} item={item} />
                    ))}
                  </View>
                </View>
              </React.Fragment>
            )}
          </View>
          {this.props.preset &&
            this.props.settings.checking[2]?.checked &&
            !this.state.started && (
              <SwipeView
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                }}
              >
                <PresetTile
                  item={this.props.preset}
                  display
                  light={this.props.themeKey != "iOS13"}
                />
              </SwipeView>
            )}
        </View>
      </View>
    )
  }
}

export default connect(
  state => {
    return {
      preset: state.app.currentPreset,
      settings: state.app.settings,
      presets: state.app.presets,
      themeKey: state.app.theme,
      showVisualHint: state.app.showVisualHint,
      showResetVisualHint: state.app.showResetVisualHint,
    }
  },
  dispatch => ({
    resetPreset: payload => dispatch(resetPreset(payload)),
    toggleVisualHint: payload => dispatch(toggleVisualHint(payload)),
    toggleResetVisualHint: payload => dispatch(toggleResetVisualHint(payload)),
    updateCurrentPreset: payload => dispatch(updateCurrentPreset(payload)),
    updateLogs: payload => dispatch(updateLogs(payload)),
    bulkUpdateLogs: payload => dispatch(bulkUpdateLogs(payload)),
  }),
)(withNamespaces()(withTheme(withRouter(Chronometer))))
