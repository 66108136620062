import React, { useEffect, useState } from "react"
import { Dimensions, StyleSheet, View } from "react-native-web"
import Chronometer from "../component/Chronometer"
import { useTheme } from "../style/themes"
import { useLocation } from "react-router-dom"

const { width } = Dimensions.get("window")
const Alarm = () => {
  const theme = useTheme()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  // const user = useSelector(state => state);
  const user = JSON.parse(localStorage.getItem("user"))

  // Get the value of a specific query parameter
  const email = queryParams.get("email")

  const [width, setWidth] = useState(getWindowSize().innerWidth)
  const [height, setHeight] = useState(getWindowSize().innerHeight)

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  useEffect(() => {
    function handleWindowResize() {
      const size = getWindowSize()
      setWidth(size.innerWidth)
      setHeight(size.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  return (
    <View style={{ ...theme?.container, ...styles.container, width }}>
      <Chronometer email={email} user={user} width={width} height={height} />
    </View>
  )
}
const styles = StyleSheet.create({
  text: {
    fontSize: width * 0.22,
    color: "white",
  },
  container: {
    alignItems: "center",
    flex: 1,
    width,
  },
  actionButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    width,
    padding: 20,
  },
})

export default Alarm
