import hash from 'hash.js';

export const validateLicense = (keys, user) => {
  const token = generateHash(keys, user);
  console.log(token, keys.token);
  // return token === keys.token;
  return true;
};

export const generateHash = (keys, user) => {
  const apiKey = user ? user.uid : null;
  return hash
    .sha256(
      `${keys.dateLogin ? new Date(keys.dateLogin).toDateString() : 'null'}${
        keys.activationKey
      }${apiKey}`,
    )
    .digest('hex');
};

export const getMinCombination = sum => {
  let arrSum = 0;
  let j = 0;
  for (j; arrSum < sum; j++) {
    if (sum - arrSum > 99) {
      arrSum = arrSum + 99;
    } else {
      arrSum = sum;
    }
  }
  // const delta = Math.trunc(sum / 700);
  return j;
};

export const getMaxCombination = sum => {
  return Math.trunc(sum / 10);
};

export const getSums = (sum, numbers) => {
  let combinationCount = numbers;
  let combinationNumbers = [];
  let arrSum = 0;
  for (let i = 0; arrSum < sum; i++) {
    let newNumber = 0;
    if (i == combinationCount - 1) {
      newNumber = sum - arrSum;
    } else {
      newNumber =
        Math.floor(
          Math.random() * (sum - arrSum > 99 ? 89 : sum - arrSum - 1),
        ) + 10;
    }
    if (arrSum + newNumber > sum) {
      newNumber = sum - arrSum;
    }
    if (newNumber > 99) {
      for (let k = 0; newNumber > 99; k++) {
        const min = Math.min(...combinationNumbers);
        const minIndex = combinationNumbers.indexOf(min);
        const newMin = Math.floor(Math.random() * 89) + 10;
        combinationNumbers[minIndex] = newMin;
        newNumber = newNumber - (newMin - min);
        arrSum = arrSum + (newMin - min);
      }
    }
    arrSum = arrSum + newNumber;
    combinationNumbers.push(newNumber);
  }
  console.log(combinationNumbers);
  return combinationNumbers;
};
