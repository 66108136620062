import React from 'react';
import { Animated, Dimensions, PanResponder } from 'react-native-web';

const { width } = Dimensions.get('window');
class SwipeView extends React.Component {
  componentWillUpdate(
    nextProps: Readonly<P>,
    nextState: Readonly<S>,
    nextContext: any,
  ): void {
    console.log('swipeDebug', nextState);
    if (nextState.ready > 0) {
      // alert(nextState.ready);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      ready: 0,
    };
    this.position = new Animated.Value(0);
    this._panResponder = PanResponder.create({
      // Ask to be the responder:
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,

      onPanResponderGrant: (evt, gestureState) => {
        // console.log(evt, gestureState);
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now
      },
      onPanResponderMove: (evt, gestureState) => {
        // console.log(
        //   'ValueXY',
        //   -gestureState.dy / 300,
        //   gestureState.numberActiveTouches,
        // );

        if (this.state.ready > 0) {
          this.position.setValue(-gestureState.dy / 300);
        }
        // The most recent move distance is gestureState.move{X,Y}
        // The accumulated gesture distance since becoming responder is
        // gestureState.d{x,y}
      },
      onPanResponderTerminationRequest: (evt, gestureState) => true,
      onPanResponderRelease: (evt, gestureState) => {
        // console.log('evt', evt.nativeEvent, gestureState);
        if (gestureState.numberActiveTouches) {
          // alert(gestureState.numberActiveTouches);
        }

        this.position.setValue(0);
        if (this.state.ready != 2) {
          this.setState({ ready: this.state.ready + 1 });
          setTimeout(() => {
            this.setState({ ready: 0 });
          }, 400);
        } else {
          this.setState({ ready: 0 });
        }
        // console.log(evt, gestureState);
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // console.log(evt, gestureState);
        // Another component has become the responder, so this gesture
        // should be cancelled
      },
      onShouldBlockNativeResponder: (evt, gestureState) => {
        // Returns whether this component should block native components from becoming the JS
        // responder. Returns true by default. Is currently only supported on android.
        return true;
      },
    });
  }

  render() {
    return (
      <Animated.View
        style={{
          opacity: this.position,
          ...this.props.style,
        }}
        {...this._panResponder.panHandlers}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}
export default SwipeView;
