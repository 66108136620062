import React from "react"
import { colors } from "../style/common"
import Chrono from "./Chrono"
import Tabs from "../component/Tabs"
import Alarm from "./Alarm"
import Clock from "./Clock"
import { IoIosAlarm, IoIosStopwatch, IoIosTimer } from "react-icons/io"
import { Image } from "react-native-web"
import { connect } from "react-redux"
import { withNamespaces } from "react-i18next"
import StopWatch from "./StopWatch"

function Home({ showVisualHint, showResetVisualHint, t, themeKey }) {
  return (
    <Tabs>
      <Clock
        title={t("World Clock")}
        icon={
          themeKey == "iOS13" ? (
            <Image
              source={require(showVisualHint
                ? "./../assets/svg/globe_with_indicator_inactive.svg"
                : showResetVisualHint
                ? "./../assets/svg/globe_with_right_indicator_inactive.svg"
                : "./../assets/svg/globe_without_indicator_inactive.svg")}
              style={{ height: "8vw", width: "8vw" }}
            />
          ) : null
        }
        activeIcon={
          themeKey == "iOS13" ? (
            <Image
              source={require(showVisualHint
                ? "./../assets/svg/globe_with_indicator_active.svg"
                : showResetVisualHint
                ? "./../assets/svg/globe_with_right_indicator_active.svg"
                : "./../assets/svg/globe_without_indicator_active.svg")}
              style={{ height: "8vw", width: "8vw" }}
            />
          ) : null
        }
      />

      <Alarm
        title={t("Alarm")}
        icon={
          themeKey == "iOS13" ? (
            <IoIosAlarm size={"8vw"} color={colors.inactiveColor} />
          ) : null
        }
        activeIcon={
          themeKey == "iOS13" ? (
            <IoIosAlarm size={"8vw"} color={colors.activeColor} />
          ) : null
        }
      />

      <Chrono
        title={t("Stopwatch")}
        icon={
          themeKey == "iOS13" ? (
            <IoIosStopwatch size={"8vw"} color={colors.inactiveColor} />
          ) : null
        }
        activeIcon={
          themeKey == "iOS13" ? (
            <IoIosStopwatch size={"8vw"} color={colors.activeColor} />
          ) : null
        }
      />
      <StopWatch
        title={t("Timer")}
        icon={
          themeKey == "iOS13" ? (
            <IoIosTimer size={"8vw"} color={colors.inactiveColor} />
          ) : null
        }
        activeIcon={
          themeKey == "iOS13" ? (
            <IoIosTimer size={"8vw"} color={colors.activeColor} />
          ) : null
        }
      />
    </Tabs>
  )
}

export default connect(
  state => ({
    showVisualHint: state.app.showVisualHint,
    showResetVisualHint: state.app.showResetVisualHint,
    themeKey: state.app.theme,
  }),
  dispatch => {},
)(withNamespaces()(Home))
