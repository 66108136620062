import React from 'react';
import { FlatList, Text, TouchableOpacity, View } from 'react-native-web';
import { colors } from '../style/common';
import { IoIosClose } from 'react-icons/io';
import PresetTile from '../component/PresetTile';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  editPreset,
  selectPreset,
  updateCurrentPreset,
} from '../redux/states/app';
import Button from '@material-ui/core/Button';

const Presets = ({ presets, select, edit, updateCurrent }) => {
  let history = useHistory();
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.background,
        padding: 20,
        paddingBottom: 60,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          history.goBack();
        }}
      >
        <IoIosClose color={'white'} size={48} style={{ marginBottom: 20 }} />
      </TouchableOpacity>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Button
          onClick={() => {
            updateCurrent({
              type: 'MS',
              name: 'recurrence',
              value: ['12'],
              keepForce: false,
              tapOnLap: false,
              showOnTap: false,
              selected: true,
              forceRule: 'STOPS',
              forceValue: 1,
              forceType: 'STOPS',
              isRecurrence: true,
            });
          }}
          style={{ fontSize: '3vw' }}
          variant="outlined"
          color="primary"
        >
          Recurrence
        </Button>
        <Button
          onClick={() => {
            select({});
          }}
          style={{ fontSize: '3vw' }}
          variant="outlined"
          color="primary"
        >
          Normal Mode
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            edit(null);
            history.push('/presets/add');
          }}
          style={{ fontSize: '3vw' }}
        >
          New Preset
        </Button>
      </View>
      <FlatList
        style={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        data={presets}
        renderItem={({ item, index }) => (
          <PresetTile item={item} index={index} />
        )}
      />
    </View>
  );
};

export default connect(
  state => ({
    presets: state.app.presets,
  }),
  dispatch => ({
    select: payload => dispatch(selectPreset(payload)),
    updateCurrent: payload => dispatch(updateCurrentPreset(payload)),
    edit: payload => dispatch(editPreset(payload)),
  }),
)(Presets);
