import React, { useEffect, useState } from "react"
import { StyleSheet, View } from "react-native-web"
import { colors } from "../style/common"
import { useDispatch, useSelector } from "react-redux"
import TextField from "@material-ui/core/TextField"
import { OptionSwitch } from "../pages/AddPreset"
import { stackPicks } from "../config/enum"
import { updateSelectedStack } from "../redux/states/app"

const StackMode = ({ onSave }) => {
  const cardNumbers = [
    "A",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
  ]
  const cardTypes = [
    { name: "S", icon: require("./../assets/svg/001-symbol-of-spades.svg") },
    {
      name: "H",
      icon: require("./../assets/svg/003-like.svg"),
    },
    { name: "C", icon: require("./../assets/svg/002-spade.svg") },
    {
      name: "D",
      icon: require("./../assets/svg/004-diamond.svg"),
    },
  ]
  const [chosen, setChosen] = useState({})
  const defaultStacks = useSelector(state => state?.app?.stacks || [])
  const stackIndex = useSelector(state => state?.app?.selectedStack)
  const [stacks, setStacks] = useState(defaultStacks)
  const settings = useSelector(state => state?.app?.settings)
  const [stack, setStack] = useState(null)
  const [withCute, setWithCute] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    setStack(stacks[stackIndex])
  }, [stackIndex, stacks])
  const moveTop = chosen => {
    const index = stack?.stack.indexOf(`${chosen?.num}${chosen?.type?.name}`)
    if (index > -1) {
      const newStack = [
        ...stack?.stack.slice(index),
        ...stack?.stack.slice(0, index),
      ]
      const newArray = [...stacks]
      newArray[stackIndex] = { ...newArray[stackIndex], stack: newStack }
      setStacks(newArray)
      setChosen({})
      setWithCute(false)
    }
  }
  const moveDown = chosen => {
    const index = stack?.stack.indexOf(`${chosen?.num}${chosen?.type?.name}`)

    if (index > -1 && index < stack?.stack?.length) {
      const newStack = [
        ...stack?.stack.slice(index + 1),
        ...stack?.stack.slice(0, index + 1),
      ]
      const newArray = [...stacks]
      newArray[stackIndex] = { ...newArray[stackIndex], stack: newStack }
      setStacks(newArray)
      setChosen({})
      setWithCute(false)
    }
  }
  const onCardChosen = c => {
    if (withCute) {
      if (settings?.stackPick === stackPicks.BOTTOM) {
        moveDown(c)
      } else {
        moveTop(c)
      }
    } else {
      save(c)
    }
  }
  const onTypeChanged = type => {
    setChosen({ ...chosen, type })
    if (chosen.num) {
      onCardChosen({ ...chosen, type })
    }
  }

  const onNumberChanged = num => {
    setChosen({ ...chosen, num })
    if (chosen.type) {
      onCardChosen({ ...chosen, num })
    }
  }

  const save = chosen => {
    const index = stack?.stack.indexOf(`${chosen?.num}${chosen?.type?.name}`)
    if (index > -1) {
      onSave(settings?.stackMode || "MS", [index + 1])
    }
  }

  const getStackLabel = stackIndex => {
    const currentStack = stacks[stackIndex]

    return settings?.stackPick === stackPicks.BOTTOM
      ? `${currentStack.name} - B:${
          currentStack?.stack[currentStack?.stack?.length - 1]
        }`
      : `${currentStack.name} - T:${currentStack?.stack[0]}`
  }

  return (
    <View style={{ marginVertical: 16, fontSize: 19 }}>
      <View
        style={{
          flexDirection: "row",
          borderTopWidth: 3,
          borderTopColor: colors.activeColor,
          paddingTop: 16,
        }}
      >
        <OptionSwitch
          value={withCute}
          onChange={setWithCute}
          label={"with cut"}
        />
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <View
          style={[
            { flex: 2 },
            // styles.button,
            // { paddingHorizontal: 0, borderWidth: 0 },
          ]}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            select
            SelectProps={{
              native: true,
            }}
            fullWidth
            value={stackIndex}
            onChange={e => {
              dispatch(updateSelectedStack(e.target.value))
            }}
          >
            {stacks
              ?.map((e, k) => ({ key: k, label: e?.name }))
              .map((option, k) => (
                <option key={option.key} value={option.key}>
                  {getStackLabel(k)}
                </option>
              ))}
          </TextField>
        </View>
        <div style={{ padding: 4 }} />
        <View
          style={[{ flex: 1 }, styles.button, { height: "100%", marginTop: 0 }]}
        >
          <p style={{ margin: 0 }}>
            Chosen: {`${chosen?.num || ""}${chosen?.type?.name || ""}`}
          </p>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {cardTypes.map(type => (
          <View
            style={[{ width: "23%" }, styles.button]}
            onClick={() => {
              onTypeChanged(type)
            }}
          >
            <img width={24} height={24} src={type.icon} alt="..." />
          </View>
        ))}
      </View>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {cardNumbers.map(num => (
          <View
            style={[{ width: num === "A" ? "100%" : "23%" }, styles.button]}
            onClick={() => {
              onNumberChanged(num)
            }}
          >
            <p>{num}</p>
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    borderColor: colors.activeColor,
    marginTop: 8,
    borderWidth: 1,
    padding: 4,
    color: colors.text,
    justifyContent: "center",
    alignItems: "center",
  },
})

export default StackMode
