import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { View } from 'react-native-web';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    color: 'white',
  },
}));
export default function RadioButtonsGroup({ defaultValue, options, onChange }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = event => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <RadioGroup
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
        }}
        aria-label="gender"
        name="gender2"
        value={value}
        onChange={handleChange}
        className={classes.formControl}
      >
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            labelPlacement="start"
          />
        ))}
      </RadioGroup>
    </View>
  );
}
