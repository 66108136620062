import React, { useEffect, useState } from "react"
import {
  Picker,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
} from "react-native-web"
import { IoIosClose } from "react-icons/io"
import { colors } from "../style/common"
import { connect } from "react-redux"
import { changeTheme, updateKeys, updateSettings } from "../redux/states/app"
import { useHistory } from "react-router-dom"
import ForceTypePicker from "../component/FoceTypePicker"
import { forceType, ruleTypes } from "../config/enum"
import { Button, Snackbar } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import { getKey, logout, setData, updateKey } from "../services/firebase"
import { logout as signout } from "./../redux/states/auth"
import TextField from "@material-ui/core/TextField"
import i18n from "./../i18n"
import { withNamespaces } from "react-i18next"
import { OptionSwitch } from "./AddPreset"
import packageJson from "../../package.json"
import MuiAlert from "@material-ui/lab/Alert"

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function Settings({
  settings,
  updateTheme,
  themeKey,
  updateSettings,
  remainingTimes,
  activationKey,
  updateKeys,
  logoutUser,
  app,
  t,
}) {
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem("user"))
  const inputUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/inputupload?id=" +
    user?.uniqueId
  const logsUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/logsupload?id=" +
    user?.uniqueId

  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const settingLabels = [
    "Long Press on Start to Force",
    "Long Press on Reset to Reset",
    "Double Swipe up to see preset",
    "Visual hint",
  ]

  const [width, setWidth] = useState(getWindowSize().innerWidth)
  const [height, setHeight] = useState(getWindowSize().innerHeight)

  function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
  }

  useEffect(() => {
    function handleWindowResize() {
      const size = getWindowSize()
      setWidth(size.innerWidth)
      setHeight(size.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  const handleClose = value => {
    if (value) {
      switchDevice()
    }
    setOpen(false)
  }
  const changeLanguage = lng => {
    i18n.changeLanguage(lng).then(r => {
      console.log(r)
    })
  }

  const handleAlert = () => {
    setOpenAlert(false)
  }

  const AlertDialog = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to switch to another device?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`you have ${remainingTimes} time remaining`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false)
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleClose(true)
          }}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )

  const updateAdvancedOptions = (i, value) => {
    updateSettings({
      uid: user.uid,
      user,
      ...settings,
      totalSettings: settings.totalSettings.map((element, index) => ({
        ...element,
        checked: i === index ? value : element.checked,
      })),
    })
  }

  function disable(i) {
    switch (i) {
      case 0:
        return settings.totalSettings[2].checked
      case 1:
        return !settings.totalSettings[0].checked
      case 2:
        return settings.totalSettings[0].checked
    }
  }

  const switchDevice = () => {
    getKey(activationKey).then(r => {
      const data = r.data()
      if (data) {
        console.log(data)
        updateKey(activationKey, {
          ...data,
          isActivated: false,
        }).then(r => {
          console.log(r)
          setData(
            { ...app, email: user.email, displayName: user.displayName },
            user.uid,
          ).then(() => {})
          logoutUser()
          logout().then(r => {
            console.log(r)
          })
          setTimeout(() => {
            history.replace("/")
          }, 500)
          updateKeys({
            dateLogin: new Date(),
            token: "null",
            activationKey: "null",
          })
        })
      }
    })
  }

  const handleCopyLink = async link => {
    try {
      setOpenAlert(true)
      navigator.clipboard.writeText(link)
    } catch (error) {
      console.error("Error copying link to clipboard: ", error)
    }
  }

  return (
    <View
      style={{
        height,
        backgroundColor: colors.background,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          paddingVertical: 20,
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            history.goBack()
          }}
        >
          <IoIosClose size={48} color={"white"} />
        </TouchableOpacity>
        <Text
          // onClick={() => {
          //   logoutUser()
          //   logout()
          //   localStorage.clear()
          //   history.goBack()
          // }}
          style={{
            fontSize: 24,
            color: "white",
            flex: 1,
            textAlign: "center",
          }}
        >
          {t("Settings")}
        </Text>
        <View
          style={{
            flexDirection: "row",
            width: 48,
            height: 48,
            alignItems: "center",
          }}
        />
      </View>
      <ScrollView
        style={{
          flex: 1,
          padding: 24,
        }}
        showsVerticalScrollIndicator={false}
      >
        <AlertDialog />
        <TextField
          id="outlined-basic"
          variant="outlined"
          select
          SelectProps={{
            native: true,
          }}
          fullWidth
          value={i18n.language}
          onChange={e => {
            changeLanguage(e.target.value)
          }}
        >
          {[
            { key: "en", label: "English" },
            { key: "fr", label: "Français" },
            { key: "de", label: "German" },
            { key: "it", label: "Italian" },
            { key: "sp", label: "Spanish" },
            { key: "dt", label: "Dutch" },
            { key: "fn", label: "Finnish" },
            { key: "pt", label: "Portuguese" },
            { key: "rs", label: "Russian" },
            { key: "vi", label: "Vietnamese" },
            { key: "ko", label: "Korea" },
            { key: "ch", label: "Chinese" },
            { key: "po", label: "Polish" },
            { key: "jp", label: "Japanese" },
            { key: "tc", label: "Traditional Chinese" },
            { key: "sw", label: "Swedish" },
          ].map(option => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </TextField>

        <View style={{ height: 16 }} />
        <TextField
          id="outlined-basic"
          variant="outlined"
          select
          SelectProps={{
            native: true,
          }}
          fullWidth
          value={settings.useComma ? "comma" : "point"}
          onChange={e => {
            console.log(e.target.value)
            updateSettings({
              uid: user.uid,
              user,
              ...settings,
              useComma: e.target.value == "comma",
            })
          }}
        >
          {[
            { key: "comma", label: "Comma" },
            { key: "point", label: "Dot" },
          ].map(option => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </TextField>

        <View style={{ height: 16 }} />
        <TextField
          id="outlined-basic"
          variant="outlined"
          select
          SelectProps={{
            native: true,
          }}
          fullWidth
          value={themeKey}
          onChange={e => {
            console.log(e.target.value)
            updateTheme({ theme: e.target.value })
          }}
        >
          {[
            { key: "iOS13", label: "iOS" },
            { key: "androidSamsung", label: "Android Samsung" },
            { key: "androidSamsungDark", label: "Android Dark Mode" },
          ].map(option => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </TextField>
        {settings.checking &&
          settings.checking.map((item, i) => (
            <View
              key={`setting:${i}`}
              style={{ flexDirection: "row", paddingVertical: 16 }}
            >
              <Switch
                value={item.checked}
                onValueChange={() => {
                  updateSettings({
                    uid: user.uid,
                    user,
                    ...settings,
                    checking: settings.checking.map((element, index) => ({
                      ...element,
                      checked: i === index ? !element.checked : element.checked,
                    })),
                  })
                }}
                style={{ marginRight: 20 }}
                activeThumbColor={colors.activeColor}
                activeTrackColor={colors.activeColor}
              />
              <Text
                style={{
                  fontSize: 16,
                  color: "white",
                }}
              >
                {settingLabels[i]}
              </Text>
            </View>
          ))}
        <View style={{ paddingVertical: 16 }}>
          <Text style={{ color: colors.text }}>{"For Manual input"}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderColor: "white",
            borderTopWidth: 2,
            paddingTop: 8,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                color: colors.text,
                marginRight: 20,
              }}
            >
              Force after:
            </Text>
            <TextInput
              style={{
                color: colors.text,
                backgroundColor: colors.background,
                borderColor: colors.text,
                borderWidth: 1,
                borderRadius: 3,
                padding: 8,
                margin: 8,
                width: width / 4,
              }}
              keyboardType={"numeric"}
              placeholder={"Number"}
              defaultValue={settings.forceValue}
              onChangeText={value => {
                updateSettings({
                  uid: user.uid,
                  user,
                  ...settings,
                  forceValue: value,
                })
              }}
            />
          </View>
          <Picker
            style={{
              color: colors.text,
              backgroundColor: colors.background,
              borderColor: colors.text,
              borderWidth: 1,
              borderRadius: 3,
              padding: 8,
              margin: 8,
            }}
            onValueChange={value => {
              updateSettings({
                uid: user.uid,
                user,
                ...settings,
                forceRule: value.toUpperCase(),
              })
            }}
            selectedValue={
              settings.forceRule ? settings.forceRule.toLowerCase() : null
            }
          >
            {Object.keys(ruleTypes).map(item => (
              <Picker.Item label={item.toLowerCase()} />
            ))}
          </Picker>
        </View>
        <ForceTypePicker
          selectedValue={
            settings.forceType ? settings.forceType : forceType.STOPS
          }
          onSelect={value => {
            updateSettings({
              uid: user.uid,
              user,
              ...settings,
              forceType: value.toUpperCase(),
            })
          }}
        />
        <Text style={{ color: "white" }}>For Manual input Total Mode:</Text>
        <View
          style={{
            marginVertical: 12,
            borderColor: "white",
            borderTopWidth: 2,
            paddingTop: 8,
          }}
        >
          <ForceTypePicker
            selectedValue={
              settings.totalForceType
                ? settings.totalForceType
                : forceType.STOPS
            }
            onSelect={value => {
              updateSettings({
                uid: user.uid,
                user,
                ...settings,
                totalForceType: value.toUpperCase(),
              })
            }}
          />
          <OptionSwitch
            value={settings.totalSettings[0].checked}
            onChange={value => {
              updateSettings({
                uid: user.uid,
                user,
                ...settings,
                totalSettings: settings.totalSettings.map((element, index) => ({
                  ...element,
                  checked: index === 0 ? value : false,
                })),
              })
            }}
            label={"Keep Force"}
          />
          {settings.totalSettings[0].checked && (
            <OptionSwitch
              value={settings.totalSettings[1].checked}
              onChange={value => {
                updateAdvancedOptions(1, value)
              }}
              label={"Change others on lap tap"}
            />
          )}
          <OptionSwitch
            value={settings.totalSettings[2].checked}
            onChange={value => {
              updateSettings({
                uid: user.uid,
                user,
                ...settings,
                totalSettings: settings.totalSettings.map((element, index) => ({
                  ...element,
                  checked: index === 2 ? value : false,
                })),
              })
            }}
            label={"Tap on Lap to Force"}
          />
        </View>

        <View style={{ paddingVertical: 6 }}>
          <Text style={{ color: colors.text }}>{"Remote Links"}</Text>
        </View>
        <View
          style={{
            marginVertical: 4,
            borderColor: "white",
            borderTopWidth: 2,
            // paddingTop: 4,
          }}
        >
          <Text
            style={{
              color: "white",
              paddingVertical: 6,
              marginTop: 10,
              fontWeight: "bold",
            }}
          >
            Remote manual input
          </Text>
          <TouchableOpacity onPress={() => handleCopyLink(inputUrl)}>
            <Text style={{ color: "white", paddingVertical: 6, marginTop: 2 }}>
              {inputUrl}
            </Text>
          </TouchableOpacity>
          <Text
            style={{
              color: "white",
              paddingVertical: 6,
              marginTop: 10,
              fontWeight: "bold",
            }}
          >
            Remote stops peek
          </Text>
          <TouchableOpacity onPress={() => handleCopyLink(logsUrl)}>
            <Text style={{ color: "white", paddingVertical: 6, marginTop: 2 }}>
              {logsUrl}
            </Text>
          </TouchableOpacity>
        </View>
        {/* <Text style={{ color: 'white' }}>For Stack Mode:</Text>
        <View
          style={{
            marginVertical: 12,
            borderColor: 'white',
            borderTopWidth: 2,
            paddingTop: 8,
          }}
        >
          <View style={{ padding: 10 }} />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="peek"
            select
            SelectProps={{
              native: true,
            }}
            fullWidth
            value={settings?.stackPick}
            onChange={e => {
              updateSettings({
                uid: user.uid,
                user,
                ...settings,
                stackPick: e.target.value,
              });
            }}
          >
            {[
              { key: 'top', label: 'Top' },
              { key: 'bottom', label: 'Bottom' },
            ].map(option => (
              <option key={option.key} value={option.key}>
                {option.label}
              </option>
            ))}
          </TextField>
          <View style={{ padding: 10 }} />
          <TextField
            id="outlined-basic"
            variant="outlined"
            label="Save as"
            select
            SelectProps={{
              native: true,
            }}
            fullWidth
            value={settings?.stackMode}
            onChange={e => {
              updateSettings({
                uid: user.uid,
                user,
                ...settings,
                stackMode: e.target.value,
              });
            }}
          >
            {[
              { key: 'MS', label: 'Milliseconds' },
              { key: 'TO', label: 'Total' },
            ].map((option, i) => (
              <option key={option.key} value={option.key}>
                {option?.label}
              </option>
            ))}
          </TextField>
        </View> */}
        {/*<Button*/}
        {/*  onClick={() => {*/}
        {/*    setOpen(true);*/}
        {/*  }}*/}
        {/*  color={'primary'}*/}
        {/*  variant={'outlined'}*/}
        {/*>*/}
        {/*  <h5>Switch device</h5>*/}
        {/*</Button>*/}
        <TextField label={packageJson.version} />
      </ScrollView>
      <View
        style={{
          height: 60,
        }}
      />

      <Snackbar
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={3000}
      >
        <Alert onClose={handleAlert} severity="success">
          Url Copied to clipboard.
        </Alert>
      </Snackbar>
    </View>
  )
}

export default connect(
  state => ({
    settings: state.app.settings || {},
    remainingTimes: state.app.remainingTimes,
    activationKey: state.app.activationKey,
    app: state.app,
    user: state.auth.user,
    themeKey: state.app.theme,
  }),
  dispatch => ({
    updateSettings: payload => dispatch(updateSettings(payload)),
    updateKeys: payload => dispatch(updateKeys(payload)),
    logoutUser: payload => dispatch(signout(payload)),
    updateTheme: payload => dispatch(changeTheme(payload)),
  }),
)(withNamespaces()(Settings))
