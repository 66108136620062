import { Dimensions } from "react-native-web"
import { colors } from "./common"

const { width, height } = Dimensions.get("window")

export default {
  colors: {
    navBarContent: "#000000",
    navBarBackground: "#000000",
    text: "#ffffff",
    activeColor: "#F4A30F",
    inactiveColor: "#7D7D7D",
    background: "#000000",
    backgroundColor: "#0000",
    startColor: "#07bc28",
    lapColor: "#c3c3c3",
    stopColor: "#AB3326",
  },
  actionButton: {
    opacity: {
      opacity: 0.15,
    },
    button: {
      width: width / 5,
      height: width / 5,
      borderRadius: width / 8,
      justifyContent: "center",
      alignItems: "center",
    },
    textHolder: {
      position: "absolute",
      width: width / 5.5 + 10,
      height: width / 5.5 + 10,
      justifyContent: "center",
      alignItems: "center",
    },
    border: {
      width: width / 5.5 + 10,
      height: width / 5.5 + 10,
      borderRadius: width / 11 + 5,
      // borderWidth: 2,
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      width: width / 5.5 + 10,
      height: width / 5.5 + 10,
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: width / 28,
      fontWeight: "500",
    },
  },
  container: {
    backgroundColor: colors.background,
  },
  chrono: {
    container: {
      height: 80,
      paddingHorizontal: 16,
      width,
    },
    layout: {
      flex: 1,
    },
    layoutWithLaps: {
      // flexDirection: 'column-reverse',
      flex: 1,
    },
    lapsContainer: { flex: 10 },
    lapsContainerNoEmpty: {
      marginBottom: 10,
      flex: 13,
    },
    lapList: {
      borderColor: "#000000",
      borderTopWidth: 0.5,
      justifyContent: "center",
    },
    text: {
      fontSize: "20vw",
      width: "12vw",
      textAlign: "center",
      // backgroundColor: 'lightblue',
      // fontFamily: 'SF Pro Display',
      fontWeight: "200",
      color: "white",
    },
    small: { width: width * 0.3 },
    big: {
      flexDirection: "row",
      // width: width * 0.55
    },
    textWrapper: {
      width: "85vw",
      flexDirection: "row",
      alignItems: "center",
      // backgroundColor: 'red',
    },
    textContainer: {
      justifyContent: "center",
      alignItems: "center",
      paddingTop: height * 0.09,
      height: height / 2.4,
    },
    textContainerWithLaps: {
      justifyContent: "center",
      alignItems: "center",
      paddingTop: height * 0.09,
      height: height / 2.4,
    },
    actionButtons: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: width - 32,
      padding: 8,
      paddingVertical: 18,
    },
  },
  lapTile: {
    text: {
      fontSize: width * 0.04,
      color: "white",
      textAlign: "left",
      paddingVertical: 12,
    },
    textLap: {
      fontSize: width * 0.04,
      color: "white",
      textAlign: "left",
      paddingVertical: 12,
    },
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderColor: "rgba(41,41,41,0.67)",
      borderBottomWidth: 1,
    },
  },
  tabs: {
    container: {
      flexDirection: "row",
    },
    doubleClick: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      padding: 8,
      paddingBottom: 24,
      paddingTop: 4,
      // backgroundColor: "#111111",
      "touch-action": "manipulation",
    },
    tabTitleText: {
      color: "#7D7D7D",
      fontWeight: "600",
      fontSize: "2.3vw",
    },
    selectedTabTitleText: {
      color: "#c37917",
      fontWeight: "600",
      fontSize: "2.3vw",
    },
  },
}
