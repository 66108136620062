import React from 'react';
import { View, Text, StyleSheet } from 'react-native-web';
import { colors } from '../style/common';

const StopWatch = () => (
  <View style={styles.container}>
    <Text style={{ color: colors.text }}>StopWatch</Text>
  </View>
);
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
  },
});
export default StopWatch;
