import React from "react"
import { Text, TouchableOpacity, View } from "react-native-web"
import { useTheme } from "../style/themes"

function ActionButton({ label, color, onPress, size, onLongPress }) {
  const theme = useTheme()

  return (
    <TouchableOpacity
      onPress={onPress}
      onLongPress={onLongPress}
      style={theme?.actionButton.container}
    >
      <View style={theme?.actionButton.textHolder}>
        <Text style={[{ color }, theme?.actionButton.text]}>{label}</Text>
      </View>
      <View style={theme?.actionButton.opacity}>
        <View style={[theme?.actionButton.border, { borderColor: color }]}>
          <View
            style={[theme?.actionButton.button, { backgroundColor: color }]}
          />
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default ActionButton
