import React from 'react'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { advancedTypes } from '../config/enum'
import {
  getMaxCombination,
  getMinCombination,
  getSums,
} from '../helpers/validation'
import { TouchableOpacity, Text } from 'react-native-web'
import { colors } from '../style/common'
import Button from '@material-ui/core/Button'
import { View } from 'react-native-web'

const Tile = ({ value, onRemove }) => {
  return (
    <div
      style={{
        color: 'black',
        backgroundColor: 'white',
        padding: 8,
        margin: 8,
      }}
    >
      {value}
      <a style={{ color: 'red', marginLeft: 5 }} onClick={onRemove}>
        x
      </a>
    </div>
  )
}
function InputTags({
  onChange,
  defaultValue = [],
  minLength = 200,
  maxValueLength = 255,
  focused,
  mode,
  maxValue,
}) {
  let textInput = React.createRef()
  const [value, setValue] = React.useState(defaultValue)
  const [currentValue, setCurrentValue] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const inputElement = React.useRef(null)
  const [possibilities, setPossibilities] = React.useState(null)
  const [temp, setTemp] = React.useState(null)
  React.useEffect(() => {
    onChange(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  React.useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  console.log(maxValueLength)
  React.useEffect(() => {
    inputElement.current.onfocus = () => {
      window.scrollTo(0, 0)
      document.body.scrollTop = 0
    }
  }, [])
  const handleKeyDown = e => {
    let myValue = e.target.value
    // let myValue = currentValue;

    if ((e.keyCode === 13 || e.keyCode === 9) && myValue.length > 0) {
      handleResult(myValue)
    }
  }

  const handleEnter = e => {
    let myValue = currentValue
    if (myValue.length > 0) {
      handleResult(myValue)
    }
  }

  const handleResult = myValue => {
    if (myValue.length > maxValueLength) {
      setErrorMessage(`Max digits for this mode is ${maxValueLength}`)
      setOpen(true)
    } else if (maxValue && parseInt(myValue) > maxValue) {
      setErrorMessage(`Value must be between 00 and ${maxValue}`)
      setOpen(true)
    } else {
      if (mode == advancedTypes.SPREAD) {
        const values = [...myValue]
        let newValues = values.length % 2 == 0 ? [] : [values[0]]
        values.forEach((e, i) => {
          if (myValue.length % 2 == i % 2) {
            newValues.push(`${values[i]}${values[i + 1]}`)
          }
        })
        setValue([...value, ...newValues])
        setCurrentValue('')
      } else if (mode == advancedTypes.ADDITION) {
        const value = parseInt(myValue)
        if (value < 901) {
          const min = getMinCombination(value)
          const max = getMaxCombination(value)
          console.log('debugADDITION', min)
          const arr = []
          for (let i = min; i < max && i < 11; i++) {
            arr.push(i)
          }
          setPossibilities(arr)
          console.log('debugADDITION', arr)
          setTemp(myValue)
          // setValue(getSums(parseInt(myValue)), 1);
        }
      } else if (value.length >= minLength) {
        setErrorMessage(
          `You can’t enter a sequence when an advanced option is activated or Total mode is selected`,
        )
        setOpen(true)
      } else {
        setValue([...value, myValue])
        setCurrentValue('')
      }
    }
  }

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleChange = e => {
    setCurrentValue(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ width: '100%', marginBottom: 8 }}>
      <Snackbar
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TextField
          inputRef={inputElement}
          // fullWidth
          variant="outlined"
          // onSubmit={
          //   (e) => {
          //     console.log("--------------------------", e);
          //   }
          // }
          label="Value"
          type="number"
          onKeyDown={handleKeyDown}
          value={currentValue}
          onChange={handleChange}
          autoFocus={focused}
        />
        <Button
          style={{
            marginLeft: '20px',
          }}
          onClick={() => {
            handleEnter()
          }}
          variant="outlined"
          color="primary"
        >
          Enter
        </Button>
      </View>

      {possibilities ? (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {possibilities.map(item => (
            <TouchableOpacity
              style={{
                padding: 8,
                backgroundColor: colors.activeColor,
                margin: 8,
                justifyContent: 'center',
                alignItems: 'center',
                width: '2rem',
              }}
              onPress={() => {
                console.log('debugADDITION', item)
                const newValue = getSums(parseInt(temp), item)
                console.log('debugADDITION', newValue)
                setValue(newValue)
                setPossibilities(null)
              }}
            >
              <Text style={{ fontSize: 16, color: 'white' }}>{item}</Text>
            </TouchableOpacity>
          ))}
        </div>
      ) : null}
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {value.map((el, index) => (
          <Tile
            value={el}
            onRemove={() => {
              setValue(value.filter((el, i) => i !== index))
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default InputTags
