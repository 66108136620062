import React from "react"
import { SafeAreaView } from "react-native-web"
import ScaledImage from "../component/ScaledImage"
import { colors } from "../style/common"

const Clock = () => {
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.background }}>
      <ScaledImage uri={require("./../assets/images/clock_screen.png")} />
    </SafeAreaView>
  )
}

export default Clock
